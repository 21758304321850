export default class CommonGetters {
  static getDoorPartLabel (doorPartIndex, allPartsCount) {
    if (allPartsCount === 1) {
      return '';
    }

    if (doorPartIndex === 0) {
      return 'Top';
    }

    if (doorPartIndex === allPartsCount - 1) {
      return 'Bund'
    }

    return 'Midter'
  }
}
