<template>
  <input
    ref="input"
    :id="id"
    :aria-invalid="state === 'invalid'"
    :aria-required="required ? true : null"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :name="name"
    :pattern="pattern"
    :placeholder="placeholder"
    :readonly="readonly"
    :required="required"
    :type="type"
    :value="value"
    :class="{
      'c-input': true,
      'form-control': true,
      'is-valid': state !== 'invalid',
      'is-invalid': state === 'invalid'
    }"
    @input="onInput"/>
</template>
<script>

export default {
  name: 'c-input',
  props: {
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    pattern: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    state: {
      type: [Boolean, String],
      default: null,
      validator: (v) => [null, 'valid', 'invalid', true, false].includes(v)
    },
    step: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: (v) => ['email', 'number', 'password', 'tel', 'text'].includes(v)
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    }
  }
}
</script>
