<template>
  <div class="content-wrapper">
    <div class="view-content flex">
      <div class="wrapper flex">
        <div class="main-content start-screen pb-5 my-auto">
          <validation-observer
            v-if="loaded"
            ref="type-selection-form"
            tag="form"
            class="type-selection-form full-width">

            <div class="form-group m-0 full-width">
              <validation-provider
                rules="required"
                name="wardrobe-position"
                v-slot="{ errors }"
                tag="div"
                class="options-wrapper">
                <c-form-radio
                  :radio-tile="true"
                  :value="false"
                  v-model="data.isWalkInCloset"
                  :class="{ 'is-invalid': errors.length, 'm-3 m-md-2': true }">
                  <span class="c-form-radio-label">Garderobe med skydedøre</span>
                </c-form-radio>
                <c-form-radio
                  :radio-tile="true"
                  :value="true"
                  v-model="data.isWalkInCloset"
                  :class="{ 'is-invalid': errors.length, 'm-3 m-md-2': true }">
                  <span class="c-form-radio-label">Garderobe uden skydedøre</span>
                </c-form-radio>
              </validation-provider>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="wrapper-narrow">
      <div class="buttons-bar">
        <c-button
          theme="primary"
          class="ml-auto"
          @click.prevent="validateForm">
          Videre
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'start-screen',
  computed: {
  },
  watch: {
  },
  data () {
    return {
      data: {
        isWalkInCloset: null
      },
      loaded: false
    }
  },
  mounted () {
    this.loadDataFromStore();
  },
  methods: {
    loadDataFromStore () {
      Vue.set(this, 'loaded', false);

      this.data.isWalkInCloset = this.$store.getters['getIsWalkInCloset'];

      Vue.nextTick(() => {
        this.loaded = true;
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['type-selection-form'], this.confirmSubmitForm);
    },
    confirmSubmitForm () {
      if (this.$store.getters['getStepCompleted'].wardrobeTypeSelection && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.submitForm();
        });
      } else {
        this.submitForm();
      }
    },
    submitForm () {
      let isSettingChanged = this.areChangesMade();
      if (isSettingChanged) {
        this.$store.commit('setWardrobePrice', {});
        this.$store.commit('resetInventory');
      }

      this.$store.commit('setIsWalkInCloset', this.data.isWalkInCloset);
      this.$store.commit('setStepCompletion', { stepName: 'wardrobeTypeSelection', stepCompleted: true });

      if (this.data.isWalkInCloset) {
        // reset door settings
        this.$store.commit('setWardrobeDoorsSettings', {});
        this.$store.commit('setWardrobeDoorsCountOption', {});

        this.$store.commit('setWardrobeGable', null);
        this.$store.commit('setWardrobeLeftEndAdjustment', false);
        this.$store.commit('setWardrobeLeftStrokeAdjustment', false);
        this.$store.commit('setWardrobePosition', null);
        this.$store.commit('setWardrobeRightEndAdjustment', false);
        this.$store.commit('setWardrobeRightStrokeAdjustment', false);
        this.$store.commit('setWardrobeUseimpact', false);
      }

      Vue.nextTick(() => {
        this.$router.push('/basic-information');
      });
    },
    areChangesMade () {
      return this.data.isWalkInCloset !== this.$store.getters['getIsWalkInCloset'];
    }
  }
};
</script>

<style lang="scss" scoped>
.type-selection-form {
  .options-wrapper {
    align-items: stretch;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
