<template>
  <div class="content-wrapper">
    <div class="view-content no-bg">
      <div class="wrapper">
        <div class="main-content inventory pb-5 relative">
          <div
            v-if="loaded"
            class="columns-wrapper wardrobe-inventory-wrapper">
            <div class="column column-left">
              <div
                id="inventory"
                class='inventory mr-3'>
                <c-button class="inventory-category"
                  theme="tile"
                  :disabled="!getIsCategoryInsertable(item)"
                  v-for="(item, index) in inventoryCategories"
                  :key="'inventory-category-' + index"
                  @click.prevent="emitOpenPopup($event, item)">

                  <div class="img-wrapper">
                    <img
                      class="inventory-category-thumbnail"
                      :src="item.thumb" />
                  </div>
                  <p class="inventory-category-name">
                    {{ item.name }}
                  </p>
                  <p class="inventory-message">
                    Dette trin afhænger af et andet, der endnu ikke er tilføjet.
                  </p>
                </c-button>
              </div>
            </div>

            <div class="column column-right">
              <div class="wardrobe-views">
                <div
                  ref="stage-wrapper"
                  class='stage-wrapper'>
                  <v-stage
                    ref="stage-front"
                    id="wardrobe-front"
                    v-on-clickaway="away"
                    class="wardrobe-stage mx-auto"
                    :config="{
                      height: canvasHeight,
                      width: canvasWidth
                    }">

                    <v-layer ref="front-dimensions">
                      <v-rect
                        ref="stage-background"
                        :config="{
                          height: canvasHeight,
                          width: canvasWidth,
                          x: 0,
                          y: 0,
                          fill: 'white'
                        }" />
                      <v-group ref="front-dimensions-width">
                        <v-rect
                          ref="front-dimension-width-start"
                          :config="{
                            x: xOffset + wallImgWidth,
                            y: yOffset - 19,
                            width: 2,
                            height: 10,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="front-dimension-width"
                          :config="{
                            x: xOffset + wallImgWidth,
                            y: yOffset - 15,
                            width: wardrobeImgWidth,
                            height: 2,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="front-dimension-width-end"
                          :config="{
                            x: xOffset + wallImgWidth + wardrobeImgWidth - 2,
                            y: yOffset - 19,
                            width: 2,
                            height: 10,
                            fill: '#1EBBF0'
                          }" />

                        <v-text :config="{text: data.width + 'mm', fontSize: 15, fill: '#1EBBF0', x: xOffset + wallImgWidth + wardrobeImgWidth / 2, y: yOffset - 35}"/>
                      </v-group>
                      <v-group ref="front-dimensions-height">
                        <v-rect
                          ref="front-dimension-height-start"
                          :config="{
                            x: xOffset - 19,
                            y: yOffset + wallImgWidth,
                            width: 10,
                            height: 2,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="front-dimension-height"
                          :config="{
                            x: xOffset - 15,
                            y: yOffset + wallImgWidth,
                            width: 2,
                            height: wardrobeImgHeight,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="front-dimension-height-end"
                          :config="{
                            x: xOffset - 19,
                            y: yOffset + wallImgWidth + wardrobeImgHeight - 2,
                            width: 10,
                            height: 2,
                            fill: '#1EBBF0'
                          }" />

                        <v-text :config="{text: data.height + 'mm', fontSize: 15, fill: '#1EBBF0', x: xOffset - 35, y: yOffset + wallImgWidth + wardrobeImgHeight / 2, rotation: -90}"/>
                      </v-group>

                      <v-group ref="front-dimensions-inventory-width">
                        <v-group
                          v-for="(dimension, dimensionIndex) in wardrobeInventoryDimensionsHoriz"
                          :key="'front-dimensions-inventory' + dimensionIndex">
                          <v-rect
                            :config="{
                              x: dimension.x,
                              y: yOffset + wardrobeImgHeight + 35,
                              width: 2,
                              height: 12,
                              fill: '#D74B35'
                            }" />

                          <v-rect
                            :config="{
                              x: dimension.x,
                              y: yOffset + wardrobeImgHeight + 40,
                              width: dimension.width,
                              height: 2,
                              fill: '#D74B35'
                            }" />

                          <v-rect
                            v-if="dimensionIndex === wardrobeInventoryDimensionsHoriz.length - 1"
                            ref="front-dimension-inner-width-end"
                            :config="{
                              x: dimension.x + dimension.width,
                              y: yOffset + wardrobeImgHeight + 35,
                              width: 2,
                              height: 12,
                              fill: '#D74B35'
                            }" />

                          <v-text :config="{text: dimension.realWidth, fontSize: 14, fill: '#D74B35', x: dimension.x + (dimension.width / 2) - 8, y: dimensionIndex % 2 === 0 ? yOffset + wardrobeImgHeight + 45 : yOffset + wardrobeImgHeight + 20}"/>
                        </v-group>
                      </v-group>

                      <v-group ref="front-wardrobe">
                        <v-rect
                          ref="room-top"
                          class="room-top"
                          :config="{
                            x: xOffset,
                            y: yOffset,
                            width: wardrobeImgWidth + (2 * wallImgWidth),
                            height: wallImgWidth,
                            fill: '#686868'
                          }" />
                        <v-rect
                          v-if="isLeftWallVisible"
                          ref="room-left"
                          class="room-left"
                          :config="{
                            x: xOffset,
                            y: yOffset,
                            width: wallImgWidth,
                            height: wardrobeImgHeight + wallImgWidth,
                            fill: '#686868'
                          }" />
                        <v-rect
                          v-if="isRightWallVisible"
                          ref="room-right"
                          class="room-right"
                          :config="{
                            x: xOffset + wardrobeImgWidth + wallImgWidth,
                            y: yOffset,
                            width: wallImgWidth,
                            height: wardrobeImgHeight + wallImgWidth,
                            fill: '#686868'
                          }" />

                        <v-rect
                          v-if="hasLeftImpact || hasLeftGable"
                          ref="wardobe-left"
                          class="wardobe-left"
                          :config="{
                            x: xOffset + wallImgWidth,
                            y: yOffset + wallImgWidth,
                            width: wardrobeImgSideWallThickness,
                            height: wardrobeImgHeight,
                            fill: '#000'
                          }" />
                        <v-rect
                          v-if="hasRightImpact || hasRightGable"
                          ref="wardrobe-right"
                          class="wardrobe-right"
                          :config="{
                            x: xOffset + wallImgWidth + wardrobeImgWidth - wardrobeImgSideWallThickness,
                            y: yOffset + wallImgWidth,
                            width: wardrobeImgSideWallThickness,
                            height: wardrobeImgHeight,
                            fill: '#000'
                          }" />

                        <v-rect
                          v-if="data.isWalkInCloset"
                          ref="room-border-left"
                          class="room-left"
                          :config="{
                            x: xOffset + wallImgWidth - 3,
                            y: yOffset + wallImgWidth,
                            width: 3,
                            height: wardrobeImgHeight,
                            fill: '#c1c1c1'
                          }" />
                        <v-rect
                          v-if="data.isWalkInCloset"
                          ref="room-border-right"
                          class="room-right"
                          :config="{
                            x: xOffset + wallImgWidth + wardrobeImgWidth,
                            y: yOffset + wallImgWidth,
                            width: 3,
                            height: wardrobeImgHeight,
                            fill: '#c1c1c1'
                          }" />
                      </v-group>
                    </v-layer>

                    <v-layer ref="front-inventory">
                      <v-rect
                        v-if="isDragging && isInsertable"
                        ref="front-in-drag"
                        :config="highlightItemFront" />

                      <v-image
                        v-for="item in frontViewVerticalItems"
                        :key="'front-view-vert-items-' + item.id"
                        :config="item"
                        :draggable="true"
                        @dragmove="handleItemDragMove"
                        @dragend="handleItemDragEnd"
                        @dragstart="handleItemDragStart"
                        @click="handleItemClick($event, item.id, item.inventoryCategoryId)">
                      </v-image>

                      <v-group
                        v-for="item in frontViewHorizontalItems"
                        :key="'front-view-horiz-items-' + item.id">
                        <v-image
                          v-if="!item.isGableTop"
                          :config="item"
                          :draggable="!item.isAdditionalTop"
                          @dragmove="handleItemDragMove"
                          @dragend="handleItemDragEnd"
                          @dragstart="handleItemDragStart"
                          @click="item.isAdditionalTop ? {} : handleItemClick($event, item.id)">
                        </v-image>
                      </v-group>
                    </v-layer>
                  </v-stage>
                </div>

                <div class='stage-wrapper'>
                  <v-stage
                    ref="stage-top"
                    id="wardrobe-top"
                    class="wardrobe-stage mb-2"
                    :config="configKonvaTop">

                    <v-layer ref="top-dimensions">
                      <v-rect
                        ref="stage-top-background"
                        :config="{
                          height: configKonvaTop.height,
                          width: configKonvaTop.width,
                          x: 0,
                          y: 0,
                          fill: 'white'
                        }" />
                      <v-group ref="top-dimensions-depth">
                        <v-rect
                          ref="top-dimension-depth-start"
                          :config="{
                            x: xOffset - 19,
                            y: topViewYOffset + wallImgWidth,
                            width: 10,
                            height: 2,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="top-dimension-depth"
                          :config="{
                            x: xOffset - 15,
                            y: topViewYOffset + wallImgWidth,
                            width: 2,
                            height: wardrobeImgDepth,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="top-dimension-depth-end"
                          :config="{
                            x: xOffset - 19,
                            y: topViewYOffset + wallImgWidth + wardrobeImgDepth - 2,
                            width: 10,
                            height: 2,
                            fill: '#1EBBF0'
                          }" />

                        <v-text :config="{text: wardrobeDepth + 'mm', fontSize: 15, fill: '#1EBBF0', x: xOffset - 35, y: topViewYOffset + wallImgWidth + wardrobeImgDepth / 2, rotation: -90}"/>
                      </v-group>
                      <v-group ref="top-dimensions-inner-width">
                        <v-rect
                          ref="top-dimension-inner-width-start"
                          :config="{
                            x: wardrobeInnerLeftOffsetX,
                            y: topViewYOffset + wardrobeImgDepth + 30,
                            width: 2,
                            height: 10,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="top-dimension-inner-width"
                          :config="{
                            x: wardrobeInnerLeftOffsetX,
                            y: topViewYOffset + wardrobeImgDepth + 34,
                            width: wardrobeImgInnerWidth,
                            height: 2,
                            fill: '#1EBBF0'
                          }" />

                        <v-rect
                          ref="top-dimension-inner-width-end"
                          :config="{
                            x: wardrobeInnerLeftOffsetX + wardrobeImgInnerWidth - 2,
                            y: topViewYOffset + wardrobeImgDepth + 30,
                            width: 2,
                            height: 10,
                            fill: '#1EBBF0'
                          }" />

                        <v-text :config="{text: wardrobInnerWidth + 'mm', fontSize: 15, fill: '#1EBBF0', x: wardrobeInnerLeftOffsetX + wardrobeImgWidth / 2, y: topViewYOffset + wallImgWidth + wardrobeImgDepth + 30}"/>
                      </v-group>

                      <v-group ref="top-wardrobe-room">
                        <v-rect
                          ref="rect"
                          class="room-back"
                          :config="{
                            x: xOffset,
                            y: topViewYOffset,
                            width: wardrobeImgWidth + (2 * wallImgWidth),
                            height: wallImgWidth,
                            fill: '#686868'
                          }" />
                        <v-rect
                          v-if="isLeftWallVisible"
                          ref="rect"
                          class="room-left"
                          :config="{
                            x: xOffset,
                            y: topViewYOffset,
                            width: wallImgWidth,
                            height: wardrobeImgDepth + wallImgWidth,
                            fill: '#686868'
                          }" />
                        <v-rect
                          v-if="isRightWallVisible"
                          ref="rect"
                          class="room-right"
                          :config="{
                            x: xOffset + wardrobeImgWidth + wallImgWidth,
                            y: topViewYOffset,
                            width: wallImgWidth,
                            height: wardrobeImgDepth + wallImgWidth,
                            fill: '#686868'
                          }" />

                        <v-rect
                          v-if="hasLeftGable || hasLeftImpact"
                          ref="rect"
                          class="wardobe-left"
                          :config="{
                            x: xOffset + wallImgWidth,
                            y: hasLeftImpact ? topViewYOffset + wallImgWidth + (wardrobeImgDepth - wardrobeImgImpactDepth) : topViewYOffset + wallImgWidth,
                            width: wardrobeImgSideWallThickness,
                            height: hasLeftImpact ? wardrobeImgImpactDepth : wardrobeImgDepth,
                            fill: '#000'
                          }" />
                        <v-rect
                          v-if="hasRightGable || hasRightImpact"
                          ref="rect"
                          class="wardrobe-right"
                          :config="{
                            x: xOffset + wallImgWidth + wardrobeImgWidth - wardrobeImgSideWallThickness,
                            y: hasRightImpact ? topViewYOffset + wallImgWidth + (wardrobeImgDepth - wardrobeImgImpactDepth) : topViewYOffset + wallImgWidth,
                            width: wardrobeImgSideWallThickness,
                            height: hasRightImpact ?  wardrobeImgImpactDepth : wardrobeImgDepth,
                            fill: '#000'
                          }" />

                        <v-rect
                          v-if="data.isWalkInCloset"
                          ref="room-border-left"
                          class="roo-topm-left"
                          :config="{
                            x: xOffset + wallImgWidth - 3,
                            y: topViewYOffset + wallImgWidth,
                            width: 3,
                            height: wardrobeImgDepth,
                            fill: '#c1c1c1'
                          }" />
                        <v-rect
                          v-if="data.isWalkInCloset"
                          ref="room-top-border-right"
                          class="room-right"
                          :config="{
                            x: xOffset + wallImgWidth + wardrobeImgWidth,
                            y: topViewYOffset + wallImgWidth,
                            width: 3,
                            height: wardrobeImgDepth,
                            fill: '#c1c1c1'
                          }" />
                      </v-group>

                      <v-group>
                        <v-rect
                          v-for="(door, index) in wardrobeImgDoors"
                          :key="'wardrobe-door-' + index"
                          :config="{
                            fill: door.fill,
                            height: door.height,
                            width: door.width,
                            x: door.x,
                            y: door.y
                          }" />
                      </v-group>

                      <!-- for testing purposes -->
                      <!-- <v-group>
                        <v-rect
                          v-for="(door, index) in wardrobeImgDoorsConnectionPoints"
                          :key="index + '-test'"
                          :config="{
                            fill: 'red',
                            height: 5,
                            width: door.width,
                            x: door.x,
                            y: yOffset + wardrobeImgDepth + wallImgWidth + 5
                          }" />
                      </v-group> -->
                    </v-layer>

                    <v-layer ref="top-wardrobe">
                      <v-group ref="top-inventory">
                         <v-rect
                          v-for="item in topViewInventory"
                          :key="'top-view-inventory-' + item.id"
                          :config="item">
                        </v-rect>
                        <v-rect
                          v-if="isDragging && isInsertable && highlightItemTop.isVisible"
                          ref="top-in-drag"
                          :config="highlightItemTop" />
                      </v-group>
                    </v-layer>
                  </v-stage>

                  <div
                    v-if="existisItemWidthCollision"
                    class="alert alert-danger alert-small px-2">
                    Du har placeret en udtræksvare foran en dørsamling. Det kan måske være svært at komme til. Brug tegningen til at vurdere om det bliver et problem. Vi anbefaler at der ikke er røde og orange farver på din tegning.
                  </div>
                </div>
              </div>
            </div>
            <div class="column column-buttons">
              <div class="inventory-item-thumbnail ml-1 mb-2 p-1">
                Pris: {{ wardrobePrice.total_price }} kr
              </div>

              <c-button
                v-if="selectedInventory.length"
                theme="warning"
                class="mb-2 ml-1"
                :is-small="true"
                @click.prevent="clearScene">
                Fjern alt inventar
              </c-button>
              <c-button
                v-if="clickedItemId"
                theme="primary"
                class="mb-2 ml-1"
                :is-small="true"
                @click.prevent="deselectItem">
                Fravælg
              </c-button>
              <c-button
                v-if="clickedItemId"
                theme="warning"
                class="mb-2 ml-1"
                :is-small="true"
                @click.prevent="removeItem">
                Fjern Element
              </c-button>

              <inventory-item-thumbnail
                v-if="clickedItemId"
                :inventory-item="selectedSourceItem" />

            </div>
          </div>
        </div>
        <wardrobe-summary
          v-if="allDataLoaded"
          :is-horizontal="true" />
      </div>
    </div>

    <div class="wrapper-narrow mt-4">
      <div class="buttons-bar">
        <c-button
          :theme="'secondary'"
          class="mr-auto"
          @click.prevent="goBack">
          Tilbage
        </c-button>

        <c-button
          class="ml-auto"
          @click.prevent="goToSummary">
          Videre
        </c-button>
      </div>
    </div>
    <inventory-selection-popup
      v-if="allDataLoaded"
      :inventoryInWardrobe="selectedInventory" />
  </div>
</template>

<script>
import Vue from 'vue';
import CustomDecimals from '@/utils/CustomDecimals.js';
import InventorySelectionPopup from '@/components/popups/InventorySelectionPopup.vue';
import InventoryItemThumbnail from '@/components/layout/InventoryItemThumbnail.vue';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'inventory',
  components: {
    'inventory-selection-popup': InventorySelectionPopup,
    'inventory-item-thumbnail': InventoryItemThumbnail
  },
  mixins: [clickaway],
  computed: {
    currentPosition () {
      return this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);
    },
    hasLeftImpact () {
      if (this.data.useImpact && this.currentPosition && this.currentPosition.impact_position && this.currentPosition.impact_position.indexOf('left') > -1) {
        return true;
      }

      return false;
    },
    hasRightImpact () {
      if (this.data.useImpact && this.currentPosition && this.currentPosition.impact_position && this.currentPosition.impact_position.indexOf('right') > -1) {
        return true;
      }

      return false;
    },
    hasLeftGable () {
      if (this.data.gable && this.currentPosition && this.currentPosition.has_gable && this.currentPosition.gable_position.indexOf('left') > -1) {
        return true;
      }

      return false;
    },
    hasRightGable () {
      if (this.data.gable && this.currentPosition && this.currentPosition.has_gable && this.currentPosition.gable_position.indexOf('right') > -1) {
        return true;
      }

      return false;
    },
    isLeftWallVisible () {
      if (!this.currentPosition) {
        return false;
      }

      if (this.currentPosition.impact_position && this.currentPosition.impact_position.indexOf('left') > -1) {
        return true;
      }

      return false;
    },
    isRightWallVisible () {
      if (!this.currentPosition) {
        return false;
      }

      if (this.currentPosition.impact_position && this.currentPosition.impact_position.indexOf('right') > -1) {
        return true;
      }

      return false;
    },
    wardrobeImgImpactDepth () {
      return this.getProportionalSizeValue(this.data.impactDepth);
    },
    wardrobeImgSideWallThickness () {
      return this.getProportionalSizeValue(this.data.sideWallThickness);
    },
    wardrobeImgDepth () {
      let gableDepth = 780;

      if (this.data.gable) {
        let currentGable = this.gableOptions.find(item => item.id === this.data.gable);

        if (currentGable && currentGable.depth) {
          gableDepth = currentGable.depth;
        }
      }

      return this.getProportionalSizeValue(gableDepth);
    },
    wardrobeDepth () {
      let depth = 780;

      if (this.data.gable) {
        let currentGable = this.gableOptions.find(item => item.id === this.data.gable);

        if (currentGable && currentGable.depth) {
          depth = currentGable.depth;
        }
      }
      return depth;
    },
    wardrobeImgInnerWidth () {
      let width = this.wardrobeImgWidth;

      if (this.hasLeftGable) {
        width -= this.wardrobeImgSideWallThickness;
      }

      if (this.hasRightGable) {
        width -= this.wardrobeImgSideWallThickness;
      }

      return CustomDecimals.toNumberFormatted(width);
    },
    wardrobInnerWidth () {
      let width = Number(this.data.width);

      if (this.hasLeftGable) {
        width -= this.data.sideWallThickness;
      }

      if (this.hasRightGable) {
        width -= this.data.sideWallThickness;
      }

      return CustomDecimals.toNumberFormatted(width, 2);
    },
    wardrobeImgInnerAvailableWidth () {
      let width = this.wardrobeImgWidth;

      if (this.hasLeftGable) {
        width -= this.wardrobeImgSideWallThickness;
      }

      if (this.hasRightGable) {
        width -= this.wardrobeImgSideWallThickness;
      }

      width -= (2 * this.inventWallAdditionalDistFromWardrobe);

      return CustomDecimals.toNumberFormatted(width);
    },
    inventoryMinXPos () {
      let offset = this.xOffset + this.wallImgWidth;

      if (this.hasLeftGable) {
        offset += this.wardrobeImgSideWallThickness;
      }

      offset += this.inventWallAdditionalDistFromWardrobe;

      return CustomDecimals.toNumberFormatted(offset, 2);
    },
    wardrobeInnerLeftOffsetX () {
      let offset = this.xOffset + this.wallImgWidth;

      if (this.hasLeftGable) {
        offset += this.wardrobeImgSideWallThickness;
      }

      return CustomDecimals.toNumberFormatted(offset, 2);
    },
    inventoryFormatted () {
      if (!this.allDataLoaded) {
        return [];
      }
      let inventoryList = JSON.parse(JSON.stringify(this.inventory));

      if (!inventoryList.length || !this.inventoryCategories.length) {
        return [];
      }

      return inventoryList.map(item => {
        let itemCategory = this.inventoryCategories.find(cate => cate.id === item.category_id);
        item.depthOnImg = item.depth ? this.getProportionalSizeValue(item.depth) : 0;
        item.heightOnImg = item.height ? this.getProportionalSizeValue(item.height) : 0;
        item.widthOnImg = item.width ? this.getProportionalSizeValue(item.width) : 0;
        item.visibleFromTop = itemCategory && itemCategory.top_view;
        item.type = itemCategory.type;

        item.snapToFloor = itemCategory && itemCategory.snap_floor;
        item.snapToCategory = itemCategory && itemCategory.snap_to_category ? itemCategory.snap_to_category : [];
        item.fitsIn = item.fits_in ? item.fits_in : [];
        item.isPullOut = !!item.is_pull_out;

        return item;
      });
    },
    itemOutOfBoundsBorders () {
      return {
        leftBorder: this.xOffset - 20,
        rightBorder: this.xOffset + this.wallImgWidth + this.wardrobeImgWidth + 20,
        topBorder: this.yOffset - 20,
        bottomBorder: this.yOffset + this.wallImgWidth + this.wardrobeImgHeight + 20
      }
    },
    configKonvaTop () {
      return {
        width: this.canvasWidth,
        height: this.wardrobeImgDepth + (2 * this.wallImgWidth) + (2 * this.yOffset)
      }
    },
    categoryTypesInScene () {
      return this.inventoryFormatted.filter(item => this.selectedInventory.indexOf(item.id) > -1).map(item => item.category_id)
    },
    wardrobeFloorPosY () {
      return this.yOffset + this.wallImgWidth + this.wardrobeImgHeight;
    },
    wardrobeAvailableSpaceHoriz () {
      let availableSpaceArray = [];

      if (!this.selectedInventory.length) {
        availableSpaceArray.push({
          x: CustomDecimals.toNumberFormatted(this.inventoryMinXPos),
          width: CustomDecimals.toNumberFormatted(this.wardrobeImgWidth)
        });
      } else {
        let verticalItems = this.frontViewInventory.filter(item => item.positionType === 'vertical');
        verticalItems.sort((CustomDecimals.sortAscBy('x')));
        for (let i = 0; i < verticalItems.length; i++) {
          if (i === 0 && Number(verticalItems[i].x) > this.inventoryMinXPos) {
            availableSpaceArray.push({
              x: CustomDecimals.toNumberFormatted(this.inventoryMinXPos),
              width: CustomDecimals.toNumberFormatted(Number(verticalItems[i].x) - Number(this.inventoryMinXPos))
            })
          }

          if (i === verticalItems.length - 1) {
            if ((Number(verticalItems[i].x) + Number(verticalItems[i].width)) < (this.inventoryMinXPos + this.wardrobeImgInnerWidth)) {
              availableSpaceArray.push({
                x: CustomDecimals.toNumberFormatted(Number(verticalItems[i].x) + Number(verticalItems[i].width)),
                width: CustomDecimals.toNumberFormatted((Number(this.inventoryMinXPos + this.wardrobeImgInnerWidth) - this.inventWallAdditionalDistFromWardrobe - (Number(verticalItems[i].x) + Number(verticalItems[i].width)))),
              })
            }
          } else {
            let spaceHeight = Math.min(Number(verticalItems[i].height), Number(verticalItems[i + 1].height));
            availableSpaceArray.push({
              x: CustomDecimals.toNumberFormatted(Number(verticalItems[i].x) + Number(verticalItems[i].width)),
              width: CustomDecimals.toNumberFormatted(Number(verticalItems[i + 1].x) - (Number(verticalItems[i].x) + Number(verticalItems[i].width))),
              height: spaceHeight,
              y: CustomDecimals.toNumberFormatted(this.wardrobeFloorPosY - spaceHeight)
            })
          }
        }
      }

      return availableSpaceArray;
    },
    wardrobeInventoryDimensionsHoriz () {
      let dimensionsArray = [];
      let offsetX = this.wardrobeInnerLeftOffsetX;

      if (!this.selectedInventory.length) {
        dimensionsArray.push({
          x: offsetX,
          width: this.wardrobeImgInnerWidth,
          realWidth: this.wardrobInnerWidth
        });
      } else {
        let verticalItems = this.frontViewInventory.filter(item => item.positionType === 'vertical');
        verticalItems.sort((CustomDecimals.sortAscBy('x')));
        for (let i = 0; i < verticalItems.length; i++) {
          let currentItem = verticalItems[i];
          let widthOnImg = 0;
          let inventoryItemRealWidth = CustomDecimals.toNumberFormatted(this.inventory.find(item => item.id === currentItem.inventoryItemId).width);
          if (i === 0 && Number(currentItem.x) > offsetX) {
            widthOnImg = CustomDecimals.toNumberFormatted(Number(currentItem.x) - offsetX);
            dimensionsArray.push({
              x: offsetX,
              width: widthOnImg,
              realWidth: CustomDecimals.toNumberFormatted(this.getProportionalRealSizeValue(widthOnImg, true), 0)
            })
          }

          let itemX = currentItem.x;
          let itemWidth = currentItem.width;
          let itemRealWidth = inventoryItemRealWidth;

          if (currentItem.inventoryCategoryId === 2) {
            // perform for gables only
            if (currentItem.isReversed) {
              itemX = currentItem.x;
              itemWidth = currentItem.width - this.inventoryBoardThicknessOnImg;
              itemRealWidth = inventoryItemRealWidth - this.inventoryBoardThickness;
              dimensionsArray.push(this.getObjectForHorizDimensions(itemX, itemWidth, itemRealWidth));

              itemX = currentItem.x + (currentItem.width - this.inventoryBoardThicknessOnImg);
              itemWidth = this.inventoryBoardThicknessOnImg;
              itemRealWidth = this.inventoryBoardThickness;
              dimensionsArray.push(this.getObjectForHorizDimensions(itemX, itemWidth, itemRealWidth));
            } else {
              itemX = currentItem.x;
              itemWidth = this.inventoryBoardThicknessOnImg;
              itemRealWidth = this.inventoryBoardThickness;
              dimensionsArray.push(this.getObjectForHorizDimensions(itemX, itemWidth, itemRealWidth));

              itemX = currentItem.x + this.inventoryBoardThicknessOnImg;
              itemWidth = currentItem.width - this.inventoryBoardThicknessOnImg;
              itemRealWidth = inventoryItemRealWidth - this.inventoryBoardThickness;
              dimensionsArray.push(this.getObjectForHorizDimensions(itemX, itemWidth, itemRealWidth));
            }
          } else {
            dimensionsArray.push(this.getObjectForHorizDimensions(itemX, itemWidth, itemRealWidth));
          }

          if (i === verticalItems.length - 1) {
            widthOnImg = CustomDecimals.toNumberFormatted((this.wardrobeInnerLeftOffsetX + this.wardrobeImgInnerWidth) - (Number(currentItem.x) + Number(currentItem.width)), 3);
            let realWidthVal = CustomDecimals.toNumberFormatted(this.getProportionalRealSizeValue(widthOnImg), 0);
            if (widthOnImg > 0) {
              dimensionsArray.push({
                x: CustomDecimals.toNumberFormatted(Number(currentItem.x) + Number(currentItem.width)),
                width: widthOnImg,
                realWidth: realWidthVal
              })
            }
          } else {
            widthOnImg = CustomDecimals.toNumberFormatted(Number(verticalItems[i + 1].x) - (Number(currentItem.x) + Number(currentItem.width)));
            if (widthOnImg > 0) {
              dimensionsArray.push({
                x: CustomDecimals.toNumberFormatted(Number(currentItem.x) + Number(currentItem.width)),
                width: widthOnImg,
                realWidth: CustomDecimals.toNumberFormatted(this.getProportionalRealSizeValue(widthOnImg), 0)
              })
            }
          }
        }
      }

      return dimensionsArray;
    },
    inventWallAdditionalDistFromWardrobe () {
      // there always needs to be 10mm space between wall/impact/gable and inventory, on both sides
      return this.getProportionalSizeValue(10);
    },
    horizItemMinSpaceBetweenOnImg () {
      return Number(this.getProportionalSizeValue(this.horizItemMinSpaceBetween));
    },
    inventoryBoardThicknessOnImg () {
      return Number(this.getProportionalSizeValue(this.inventoryBoardThickness));
    },
    inventoryBoardHeightOnImg () {
      return Number(this.getProportionalSizeValue(this.inventoryBoardHeight));
    },
    frontViewVerticalItems () {
      return this.frontViewInventory.filter(item => item.positionType === 'vertical');
    },
    frontViewHorizontalItems () {
      return this.frontViewInventory.filter(item => item.positionType === 'horizontal');
    },
    wardrobeImgDoors () {
      let baseY = this.topViewYOffset + this.wardrobeImgDepth + this.wallImgWidth;
      let baseX = this.wardrobeInnerLeftOffsetX;
      let doorWithOnImg = this.getProportionalSizeValue(this.data.doors.door_width);
      let doorsOutput = [];
      let doorPartWidth = this.wardrobeImgInnerWidth / this.data.doors.number;
      let doorPartWidthDiif = doorWithOnImg - doorPartWidth;

      for (let i = 0; i < this.data.doors.number; i++) {
        let newDoorX = baseX;
        let newDoorY = baseY;
        if (i > 0) {
          newDoorX += ((doorPartWidth * i) - doorPartWidthDiif);
        }
        if ((i % 2) > 0) {
          newDoorY -= 2;
        } else {
          newDoorY -= 10;
        }
        let newDoor = {
          fill: 'black',
          height: 2,
          width: doorWithOnImg,
          x: newDoorX,
          y: newDoorY
        }

        doorsOutput.push(newDoor);
      }

      return doorsOutput
    },
    wardrobeImgDoorsConnectionPoints () {
      let output = [];
      let baseX = this.wardrobeInnerLeftOffsetX;
      let doorPartWidth = this.wardrobeImgInnerWidth / this.data.doors.number;
      let safeSpaceOnImg = this.getProportionalSizeValue(50);

      for (let i = 1; i < this.data.doors.number; i++) {
        let newDoorX = baseX;
        newDoorX += doorPartWidth * i;

        output.push({
          x: newDoorX - safeSpaceOnImg,
          width: safeSpaceOnImg * 2
        });
      }

      return output
    },
    widthProportion () {
      return CustomDecimals.toNumberFormatted(Number(this.wardrobeImgHeight) / Number(this.data.height), 5);
    },
    allDataLoaded () {
      return this.loaded && this.isResizeComplete;
    },
    includeDoorCollisions () {
      if (this.data && this.data.doors && this.data.doors.number && this.data.doors.number === 4) {
        return false;
      }

      return true;
    },
  },
  watch: {
    frontViewInventory: {
      handler () {
        if (this.isDragging) {
          return;
        }

        if (!this.includeDoorCollisions) {
          this.existisItemWidthCollision = false
          return;
        }

        this.existisItemWidthCollision = !!(this.frontViewInventory.find(item => item.isInCollision))
      },
      deep: true
    },
    loaded (newVal) {
      if (newVal) {
        Vue.nextTick(() => {
          this.initScene();
        });
      }
    },
    wardrobeAvailableSpaceHoriz (newValue, oldValue) {
      if (!this.sceneInitiated) {
        return;
      }
      let spacesChanged = false;
      if (newValue.length !== oldValue.length) {
        spacesChanged = true;
      } else {
        for (let i = 0; i < newValue.length; i++) {
          if ((newValue[i].x !== oldValue[i].x) || (newValue[i].width !== oldValue[i].width)) {
            spacesChanged = true;
          }
        }
      }

      if (!spacesChanged) {
        return;
      }

      Vue.nextTick(() => {
        this.removeInvalidUnattachedItems();
        let flexShelf = this.inventoryFormatted.find(item => item.width === 'flex' && item.category_id === 3);
        if (!flexShelf) {
          return
        }
        let minWidth = this.getProportionalSizeValue(flexShelf.min_width);
        let maxWidth = this.getProportionalSizeValue(flexShelf.max_width);

        for (let j = 1; j < (newValue.length - 1); j++) {
          let currentFlexTop = this.frontViewInventory.find(item => item.x === newValue[j].x && item.isAdditionalTop);

          if (!currentFlexTop && newValue[j].width <= maxWidth && newValue[j].width >= minWidth) {
            this.addItem(flexShelf.id, false, null, true, newValue[j].x, newValue[j].width);
          }
        }
      });
    },
    '$store.state.wardrobe.price': function(newVal) {
      this.wardrobePrice = newVal;
    }
  },
  data () {
    return {
      data: {
        doors: null,
        gable: null,
        height: null,
        impactDepth: 0,
        sideWallThickness: 0,
        useImpact: false,
        wardrobeDoors: {},
        wardrobePosition: null,
        width: null,
        isWalkInCloset: false,
      },
      gableOptions: [],
      wardrobePositions: [],
      inventoryCategories: [],
      inventory: [],
      loaded: false,
      sceneInitiated: false,
      selectedInventory: [],
      frontViewInventory: [],
      topViewInventory: [],
      dragItemMouseOffeset: {
        x: 0,
        y: 0
      },
      canvasWidth: 1010,
      canvasHeight: 840,
      wardrobeImgHeight: 0,
      wardrobeImgWidth: 0,
      wallImgWidth: 12,
      xOffset: 40,
      yOffset: 40,
      topViewYOffset: 5,
      isDragging: false,
      highlightItemFront: {
        sourceItemId: '',
        height: 0,
        width: 0,
        x: 0,
        y: 0,
        strokeWidth: 4,
      },
      highlightItemTop: {
        sourceItemId: '',
        height: 0,
        width: 0,
        x: 0,
        y: 0,
        isVisible: false,
        strokeWidth: 4,
      },
      dragSourceID: '',
      isDrag: false,
      inventoryInDragHeight: 0,
      inventoryInDragSrc: '',
      inventoryInDragWidth: 0,
      inventoryInDragX: 0,
      inventoryInDragId: 0,
      inventoryInDragReversedSrc: '',
      inventoryInDragIsReversed: false,
      isInsertable: false,
      clickedItemId: 0,
      selectedSourceItem: null,
      horizItemMinSpaceBetween: 64,
      inventoryBoardThickness: 19,
      inventoryBoardHeight: 19,
      resizeTime: null,
      resizeTimeout: false,
      resizeTimeDelta: 500,
      resizeInProgress: false,
      collisionColor: '#FDC280',
      higlightFillColor: '#00D2FF',
      higlightStrokeColor: 'rgba(30, 187, 240, .7)',
      selectedItemFillColor: 'rgba(30, 187, 240, .8)',
      isResizeComplete: false,
      wardrobePrice: {},
      itemsRemovedOnStateLoad: [],
      existisItemWidthCollision: false
    }
  },
  mounted () {
    this.$bus.$on('add-item-to-wardrobe', this.addItem);
    window.addEventListener('resize', this.windowResize, false);
    window.addEventListener('keydown', this.keyAction);

    this.data.doors = this.$store.getters['getWardrobeDoorsCountOption'];
    this.data.gable = this.$store.getters['getWardrobeGable'];
    this.data.height = this.$store.getters['getWardrobeHeight'];
    this.data.useImpact = this.$store.getters['getWardrobeUseImpact'];
    this.data.wardrobePosition = this.$store.getters['getWardrobePosition'];
    this.data.width = this.$store.getters['getWardrobeWidth'];
    Vue.set(this.data, 'wardrobeDoors', JSON.parse(JSON.stringify(this.$store.getters['getWardrobeDoorsSettings'])));

    this.data.isWalkInCloset = this.$store.getters['getIsWalkInCloset']
    this.data.impactDepth = 120; // 120cm, wartość będzie pobierana z API
    this.data.sideWallThickness = 12; // 1.2cm, wartość będzie pobierana z API

    this.gableOptions = this.$store.getters['getDataGableOptions'];
    this.wardrobePositions = this.$store.getters['getDataWardrobePositions'];
    this.inventoryCategories = this.$store.getters['getDataInventoryCategories'];
    this.inventory = this.$store.getters['getDataInventory'];

    let maxElementHeight = Math.max(...this.inventory.map(item => item.height));

    if (maxElementHeight) {
      this.inventoryBoardHeight = maxElementHeight;
    }

    this.highlightItemFront.fill = this.higlightFillColor;
    this.highlightItemFront.stroke = this.higlightStrokeColor;
    this.highlightItemTop.fill = this.higlightFillColor;
    this.highlightItemTop.stroke = this.higlightStrokeColor;

    this.wardrobePrice = this.$store.getters['getWardrobePrice'];

    Vue.nextTick(() => {
      this.loaded = true;
    });
  },
  methods: {
    emitOpenPopup (e, inventoryCategory) {
      let inventoryInCategory = this.inventoryFormatted.filter(item => item.category_id === inventoryCategory.id);
      this.$bus.$emit('inventory-selection-popup-show', inventoryCategory.name, inventoryInCategory);
    },
    getIsCategoryInsertable (inventoryCategory) {
      if (!inventoryCategory.snap_to_category || !inventoryCategory.snap_to_category.length) {
        return true;
      }

      let snapToCategoriesOnScene = inventoryCategory.snap_to_category.some(item => this.categoryTypesInScene.indexOf(item) > -1)

      if (inventoryCategory.type !== 'horizontal') {
        return snapToCategoriesOnScene;
      }

      if (snapToCategoriesOnScene) {
        return snapToCategoriesOnScene;
      }

      let inventoryInCategory = this.inventoryFormatted.filter(item => item.category_id === inventoryCategory.id);
      let flexItem = inventoryInCategory.find(item => item.width === 'flex');
      if (!flexItem) {
        return snapToCategoriesOnScene;
      }

      // check if there are at leas 2 walls
      let wallsInWardrobe = this.selectedInventory.filter(item => item === 2);
      return !!(wallsInWardrobe && wallsInWardrobe.length > 1);
    },
    getProportionalSizeValue (value) {
      return CustomDecimals.toNumberFormatted(value * this.widthProportion);
    },
    getProportionalRealSizeValue (value, roundTo10) {
      let newValue = value / this.widthProportion;
      if (roundTo10 && newValue > 9 && newValue < 11) {
        newValue = 10;
      }
      return CustomDecimals.toNumberFormatted(newValue);
    },
    getProportionalRealYValue (posYOnScene) {
      let value = this.wardrobeFloorPosY - posYOnScene;
      let newValue = value / this.widthProportion;
      return CustomDecimals.toNumberFormatted(newValue);
    },
    getProportionaYValue (posYReal) {
      let newValue = posYReal * this.widthProportion;
      return CustomDecimals.toNumberFormatted(this.wardrobeFloorPosY - newValue);
    },
    getProportionalRealXValue (posXOnScene) {
      let value = posXOnScene - this.xOffset - this.wallImgWidth;
      return CustomDecimals.toNumberFormatted(this.getProportionalRealSizeValue(value), 0);
    },
    getProportionaXValue (posXReal) {
      let newValue = this.getProportionalSizeValue(posXReal);
      return CustomDecimals.toNumberFormatted(newValue + this.xOffset + this.wallImgWidth);
    },
    getNewItemId (inventoryItem) {
      let categoryName = this.inventoryCategories.find(cate => cate.id === inventoryItem.category_id).name;
      return 'item-' + inventoryItem.type + '-' + categoryName + '-' + inventoryItem.width + '-' + CustomDecimals.generateUUID();
    },
    addItem (itemID, itemFromStore, parentGable = null, isAdditionalTop = false, newItemPosX = null, newItemWidth = null) {
      let inventoryItem = this.inventoryFormatted.find(item => item.id === itemID);
      if (itemFromStore && this.itemsRemovedOnStateLoad.indexOf(itemFromStore.stageID) > -1) {
        inventoryItem = null;
      }
      if (!inventoryItem) {
        if (itemFromStore) {
          if (itemFromStore.attachedItems) {
            for (let i = 0; i < itemFromStore.attachedItems.length; i++) {
              this.$store.commit('removeInventoryAttachedItem', { stageID: itemFromStore.stageID, childID: itemFromStore.attachedItems[i] });
              this.itemsRemovedOnStateLoad.push(itemFromStore.attachedItems[i]);
            }
          }

          this.itemsRemovedOnStateLoad.push(itemFromStore.stageID);
          this.$store.commit('removeInventoryItem', itemFromStore.stageID);
        }
        return;
      }

      if (itemFromStore && itemFromStore.isGableTop) {
        this.$store.commit('removeInventoryItem', itemFromStore.stageID);
        return;
      }

      let newItemID = this.getNewItemId(inventoryItem);
      let posX = null;
      let posY = null;
      let isNotAttachedValue = false;
      let inventoryItemWidthOnImg = inventoryItem.widthOnImg;
      let attachedItemsArray = [];

      if (itemFromStore) {
        newItemID = itemFromStore.stageID;
        posX = this.getProportionaXValue(itemFromStore.posX);
        isNotAttachedValue = itemFromStore.isNotAttached;
        attachedItemsArray = itemFromStore.attachedItems;

        if (inventoryItem.type === 'vertical') {
          posY = CustomDecimals.toNumberFormatted(this.wardrobeFloorPosY - inventoryItem.heightOnImg);
        } else {
          posY = this.getProportionaYValue(itemFromStore.posY);
        }

        if (!inventoryItemWidthOnImg && inventoryItem.width === 'flex') {
          inventoryItemWidthOnImg = this.getProportionalSizeValue(itemFromStore.width);
        }
      } else if (newItemPosX && newItemWidth) {
        posX = newItemPosX;
        posY = CustomDecimals.toNumberFormatted(this.wardrobeFloorPosY - this.inventoryBoardHeightOnImg);
        inventoryItemWidthOnImg = newItemWidth;
        isNotAttachedValue = true;
      } else if (parentGable) {
        posX = parentGable.x;
        if (!parentGable.isReversed) {
          posX += this.inventoryBoardThicknessOnImg
        }
        posY = parentGable.y;
      } else {
        posX = 0;
        posY = CustomDecimals.toNumberFormatted(this.wardrobeFloorPosY - inventoryItem.heightOnImg);

        if (!this.selectedInventory.length) {
          posX = this.wardrobeAvailableSpaceHoriz[0].x + (this.wardrobeAvailableSpaceHoriz[0].width / 2);
        } else if (inventoryItem.type === 'horizontal') {
          let newPosition = this.getNewHorizItemPosition(inventoryItem, newItemID);
          posX = newPosition.x;
          posY = newPosition.y;
          isNotAttachedValue = newPosition.isNotAttached;

          if (inventoryItem.width === 'flex' && newPosition.newWidth) {
            inventoryItemWidthOnImg = newPosition.newWidth;
          }
        } else {
          posX = this.getNewItemPosX(inventoryItem, newItemID);
        }
      }

      if (!posX || !posY) {
        Vue.swal({
          title: 'Kan ikke tilføje element',
          text: 'Valgte element kan ikke blive tilføjet. Ingen ledig plads blev fundet',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
        return;
      }

      posX = CustomDecimals.toNumberFormatted(posX);

      let itemIsReversed = !!this.inventoryInDragIsReversed;
      if (itemFromStore) {
        itemIsReversed = itemFromStore.isReversed;
      }
      let itemFrontImg = new window.Image();
      itemFrontImg.src = inventoryItem.front_reversed && itemIsReversed ? inventoryItem.front_reversed : inventoryItem.front;

      let newItem = {
        id: newItemID,
        attachedItems: attachedItemsArray,
        height: inventoryItem.heightOnImg,
        image: itemFrontImg,
        inventoryCategoryId: inventoryItem.category_id,
        inventoryItemId: inventoryItem.id,
        isNotAttached: isNotAttachedValue,
        isReversed: itemIsReversed,
        isPullOut: inventoryItem.isPullOut,
        src: inventoryItem.front,
        positionType: inventoryItem.type,
        width: inventoryItemWidthOnImg,
        x: posX,
        y: posY,
        lastValidX: posX,
        lastValidY: posY,
        opacity: 1,
        stroke: this.higlightStrokeColor,
        strokeWidth: 0,
        fill: ''
      }

      if (isAdditionalTop || (itemFromStore && itemFromStore.isAdditionalTop)) {
        newItem.isAdditionalTop = true;
        newItem.depthOnImg = inventoryItem.depthOnImg;
      }
      if (parentGable || (itemFromStore && itemFromStore.isGableTop)) {
        newItem.isGableTop = true;

        if (parentGable && parentGable.attachedItems) {
          if (parentGable.attachedItems.indexOf(item => item.id === newItemID) < 0) {
            this.updateAttachedItems(parentGable, newItemID);
          }
        }
      }

      if (inventoryItem.width === 'flex') {
        newItem.isFlexWidth = true;
      }

      this.frontViewInventory.push(newItem);
      this.selectedInventory.push(inventoryItem.id);

      if (!itemFromStore) {
        let attachedItemsArrayCopy = JSON.parse(JSON.stringify(attachedItemsArray));
        let itemForStore = JSON.parse(JSON.stringify(inventoryItem));
        if (inventoryItem.width === 'flex' && inventoryItemWidthOnImg) {
          itemForStore.isFlexWidth = true;
          itemForStore.width = this.getProportionalRealSizeValue(inventoryItemWidthOnImg);
        }
        itemForStore.stageID = newItemID;
        itemForStore.attachedItems = attachedItemsArrayCopy;
        itemForStore.isReversed = itemIsReversed;
        itemForStore.isNotAttached = isNotAttachedValue;
        itemForStore.posX = this.getProportionalRealXValue(posX);
        itemForStore.posY = this.getProportionalRealYValue(posY);
        if (isAdditionalTop) {
          itemForStore.isAdditionalTop = true;
        }

        if (parentGable) {
          itemForStore.isGableTop = true;
        }

        this.$store.commit('addInventoryItem', itemForStore);

        if (!isAdditionalTop && !parentGable) {
          setTimeout(() => {
            this.selectItem(newItemID, inventoryItem.category_id);
          }, 250);
        }
      }

      if (newItem.inventoryCategoryId === 2) {
        // perform for gables only
        // add gable top shelf
        let gableTopShelf = this.inventoryFormatted.find(item => item.category_id === 3 && item.fitsIn.indexOf(inventoryItem.id) > -1);
        this.addItem(gableTopShelf.id, false, newItem);
      }

      if (inventoryItem.isPullOut) {
        this.setIfItemHasCollision(this.frontViewInventory[this.frontViewInventory.length - 1]);
        this.updateTopViewItemInCollision();
      }

      this.updatePrice();

      if (!inventoryItem.visibleFromTop && !newItem.isAdditionalTop) {
        return;
      }


      let fillColor = inventoryItem.category_id === 2 ? '#1E1E1C' : '#7E7e7E';

      this.topViewInventory.push({
        id: newItemID,
        height: inventoryItem.depthOnImg,
        inventoryCategoryId: inventoryItem.category_id,
        inventoryItemId: inventoryItem.id,
        width: inventoryItemWidthOnImg,
        x: posX,
        y: this.topViewYOffset + this.wallImgWidth,
        lastValidX: posX,
        lastValidY: this.topViewYOffset + this.wallImgWidth,
        fill: fillColor,
        baseFill: fillColor
      });
    },
    handleItemDragStart (e) {
      // console.log('handleItemDragStart', e);
      Vue.set(this, 'isDragging', true);
      Vue.set(this.highlightItemFront, 'height', e.target.attrs.height);
      Vue.set(this.highlightItemFront, 'width', e.target.attrs.width);
      Vue.set(this.highlightItemFront, 'fill', this.higlightFillColor);
      Vue.set(this.highlightItemFront, 'stroke', this.higlightStrokeColor);

      let frontViewItem = this.frontViewInventory.find(ele => ele.id === e.target.attrs.id);
      this.selectItem(frontViewItem.id, frontViewItem.inventoryCategoryId);

      let sourceItem = this.inventoryFormatted.find(ele => ele.id === frontViewItem.inventoryItemId);
      if (sourceItem && sourceItem.visibleFromTop) {
        Vue.set(this.highlightItemTop, 'height', sourceItem.depthOnImg);
        Vue.set(this.highlightItemTop, 'width', e.target.attrs.width);
        Vue.set(this.highlightItemTop, 'y', this.topViewYOffset + this.wallImgWidth);
        Vue.set(this.highlightItemTop, 'fill', this.higlightFillColor);
        Vue.set(this.highlightItemTop, 'stroke', this.higlightStrokeColor);
        Vue.set(this.highlightItemTop, 'isVisible', true);
      } else {
        Vue.set(this.highlightItemTop, 'isVisible', false);
      }

      let allItemsWidth = frontViewItem.width;
      let allItemsX = frontViewItem.x;

      if (frontViewItem.inventoryCategoryId === 2 && frontViewItem.attachedItems.length) {
        let allHorizontalChildren = this.frontViewInventory.filter(ele => frontViewItem.attachedItems.indexOf(ele.id) > -1 && ele.positionType === 'horizontal');

        for (let i = 0; i < allHorizontalChildren.length; i++) {
          allHorizontalChildren[i].opacity = 0;
        }
      }

      Vue.set(this.dragItemMouseOffeset, 'y', e.evt.offsetY - e.target.attrs.y);

      Vue.set(this, 'inventoryInDragWidth', allItemsWidth);
      Vue.set(this, 'inventoryInDragX', allItemsX);

      frontViewItem.lastValidX = e.target.attrs.x;
      frontViewItem.lastValidY = e.target.attrs.y;
    },
    handleItemDragMove (e) {
      // console.log('handleItemDragMove ', e);
      let element = e.target;
      let sourceItem = this.inventoryFormatted.find(item => item.id === element.attrs.inventoryItemId);

      if (this.checkItemOutOfBound(e.evt.offsetX, e.evt.offsetY, element.attrs.x, element.attrs.width)) {
        Vue.set(this, 'isInsertable', false);

        if (sourceItem.type === 'vertical') {
          this.updateTopView(element.attrs.id, element.attrs.x);
        }
        return;
      }

      Vue.set(this.highlightItemFront, 'x', element.attrs.x);
      Vue.set(this.highlightItemFront, 'y', element.attrs.y);
      Vue.set(this.highlightItemTop, 'x', element.attrs.x);

      let snapXPos = null;
      let snapYPos = null;

      if (sourceItem.type === 'vertical') {
        snapXPos = this.getItemXOnCanvas(e.evt.offsetX, sourceItem, element.attrs.id, this.inventoryInDragWidth);
        snapYPos = this.wardrobeFloorPosY - sourceItem.heightOnImg;
      } else {
        let newPosition = this.getHorizItemPosOnCanvas(e.evt.offsetX, CustomDecimals.toNumberFormatted(e.evt.offsetY - this.dragItemMouseOffeset.y), sourceItem, element.attrs.id, element.attrs.height);
        snapXPos = newPosition.x;
        snapYPos = newPosition.y;

        if (sourceItem.width === 'flex' && newPosition.newWidth) {
          Vue.set(this.highlightItemFront, 'width', newPosition.newWidth);
          element.attrs.width = newPosition.newWidth;
        }
      }

      if (!snapXPos || !snapYPos) {
        Vue.set(this, 'isInsertable', false);
      } else {
        Vue.set(this.highlightItemFront, 'x', snapXPos);
        Vue.set(this.highlightItemFront, 'y', snapYPos);
        Vue.set(this.highlightItemTop, 'x', snapXPos);
        Vue.set(this, 'isInsertable', true);

        element.attrs.lastValidX = snapXPos;
        element.attrs.lastValidY = snapYPos;
      }

      if (sourceItem.is_pull_out) {
        let elementWidth = element.attrs.width;
        let connectionPointInCollision = this.wardrobeImgDoorsConnectionPoints.find(item => (item.x >= snapXPos && item.x <= snapXPos + elementWidth) || (item.x + item.width >= snapXPos && item.x + item.width <= snapXPos + elementWidth));

        if (connectionPointInCollision && this.includeDoorCollisions) {
          Vue.set(this.highlightItemFront, 'fill', this.collisionColor);
          Vue.set(this.highlightItemFront, 'stroke', this.collisionColor);
        } else {
          Vue.set(this.highlightItemFront, 'fill', this.higlightFillColor);
          Vue.set(this.highlightItemFront, 'stroke', this.higlightStrokeColor);
        }
      }

      if (sourceItem.type === 'vertical') {
        this.updateTopView(element.attrs.id, element.attrs.x);
      }
    },
    handleItemDragEnd (e) {
      // console.log('handleItemDragEnd ', e);
      Vue.set(this, 'isDragging', false);

      let sourceItem = this.inventoryFormatted.find(item => item.id === e.target.attrs.inventoryItemId);
      let frontViewItem = this.frontViewInventory.find(ele => ele.id === e.target.attrs.id);

      if (!sourceItem) {
        return;
      }

      if (this.checkItemOutOfBound(e.evt.offsetX, e.evt.offsetY, e.target.attrs.x, e.target.attrs.width)) {
        Vue.set(this, 'isInsertable', false);
        this.removeItemFromScene(frontViewItem.id);
        return
      }

      let goToX = e.target.attrs.x;
      let goToY = e.target.attrs.y;
      let goToWidth = e.target.attrs.width;

      if (sourceItem.type === 'vertical') {
        goToX = this.getItemXOnCanvas(e.evt.offsetX, sourceItem, frontViewItem.id, this.inventoryInDragWidth, true);
        goToY = this.wardrobeFloorPosY - sourceItem.heightOnImg;
      } else {
        let newPosition = this.getHorizItemPosOnCanvas(e.evt.offsetX, CustomDecimals.toNumberFormatted(e.evt.offsetY - this.dragItemMouseOffeset.y), sourceItem, frontViewItem.id, frontViewItem.height, true);
        goToX = newPosition.x;
        goToY = newPosition.y;

        if (sourceItem.width === 'flex' && newPosition.newWidth) {
          goToWidth = newPosition.newWidth;
        }
      }

      if (!goToX || !goToY) {
        goToX = e.target.attrs.lastValidX;
        goToY = e.target.attrs.lastValidY;
      }

      Vue.nextTick(() => {
        Vue.set(this, 'inventoryInDragWidth', 0);
        Vue.set(this, 'inventoryInDragX', 0);

        e.target.to({
          duration: 0.1,
          x: goToX,
          y: goToY
        });

        if (sourceItem.width === 'flex' && goToWidth) {
          frontViewItem.width = goToWidth;
        }

        this.updateViews(frontViewItem.id, goToX, goToY, sourceItem);

        Vue.nextTick(() => {
          if (frontViewItem.positionType === 'vertical') {
            let attachedItemsChain = this.getAllVerticalAttachedItemsInChain(frontViewItem);
            this.updateAttachedItemsPosition(frontViewItem, attachedItemsChain.chain);
          }
        })
      })
    },
    getItemXOnCanvas (mouseX, sourceItem, movingItemId, movingItemWidth, updateAttachedItems, itemsToIgnore) {
      let snapXPos = null;

      if (sourceItem.category_id === 4) {
        snapXPos = this.getWallPositionX(mouseX, sourceItem, movingItemId, movingItemWidth);
      } else {
        snapXPos = this.getSnapPositionX(mouseX, sourceItem, movingItemId, movingItemWidth, updateAttachedItems, false, itemsToIgnore);
      }

      return snapXPos;
    },
    getHorizItemPosOnCanvas (mouseX, mouseY, sourceItem, movingItemId, movingItemHeight, updateAttachedItems) {
      let snapXPos = null;
      let snapYPos = null;
      let snapObj = this.getSnapHorizItemPositionX(mouseX, sourceItem, movingItemId, updateAttachedItems);
      if (snapObj && 'x' in snapObj) {
        snapXPos = snapObj.x;
      }

      if (!snapXPos) {
        return { x: null, y: null };
      }

      snapXPos = CustomDecimals.toNumberFormatted(snapXPos);

      let itemToFitIn = snapObj.parentItem;

      let itemNewWidth = itemToFitIn.width;
      let posY = mouseY;
      if ('id' in itemToFitIn) {
        itemNewWidth -= this.inventoryBoardThicknessOnImg;
      } else {
        let itemToFitInPosY = this.wardrobeFloorPosY - itemToFitIn.height;
        posY = Math.max(mouseY, itemToFitInPosY);
      }

      if (!itemToFitIn) {
        console.log('nie ma do czego przyczepić');
        return { x: null, y: null };
      }

      snapYPos = this.getSnapHorizItemPositionY(posY, itemToFitIn, movingItemId, movingItemHeight);

      if (snapYPos) {
        snapYPos = CustomDecimals.toNumberFormatted(snapYPos);
      }

      return { x: snapXPos, y: snapYPos, newWidth: CustomDecimals.toNumberFormatted(itemNewWidth) };
    },
    getWallPositionX (mouseX, sourceItem, movingItemId, movingItemWidth) {
      if (mouseX < this.inventoryMinXPos) {
        mouseX = this.inventoryMinXPos;
      } else if ((mouseX + movingItemWidth) >= ((this.inventoryMinXPos + this.wardrobeImgInnerAvailableWidth))) {
        mouseX = (this.inventoryMinXPos + this.wardrobeImgInnerAvailableWidth) - movingItemWidth;
      }

      if (this.checkExistsElementVertical(mouseX, movingItemId, movingItemWidth)) {
        let snapXPos = this.getSnapPositionX(mouseX, sourceItem, movingItemId, movingItemWidth, false, true);

        if (snapXPos) {
          return snapXPos;
        }
        return null;
      }
      return mouseX
    },
    getSnapPositionX (mouseX, sourceItem, movingItemId, movingItemWidth, addItemAsChild, checkAnyVertical = false, itemsToIgnore = null) {
      let itemsInCategory = [];

      if (checkAnyVertical) {
        itemsInCategory = this.frontViewInventory.filter(item => (item.positionType === 'vertical') && (item.id !== movingItemId));
      } else if (itemsToIgnore) {
        itemsInCategory = this.frontViewInventory.filter(item => (sourceItem.snapToCategory.indexOf(item.inventoryCategoryId) > -1) && (itemsToIgnore.indexOf(item.id) < 0));
      } else {
        itemsInCategory = this.frontViewInventory.filter(item => (sourceItem.snapToCategory.indexOf(item.inventoryCategoryId) > -1) && (item.id !== movingItemId));
      }

      let currentFrontViewElement = this.frontViewInventory.find(item => item.id === movingItemId);
      if (!itemsToIgnore && currentFrontViewElement && currentFrontViewElement.attachedItems && currentFrontViewElement.attachedItems.length) {
        itemsInCategory = itemsInCategory.filter(item => currentFrontViewElement.attachedItems.indexOf(item.id) < 0);
      }

      let snapItemAfter = false;
      let snapToItem = null;

      if (itemsInCategory.length) {
        let closestItemX = null;

        for (let i = 0; i < itemsInCategory.length; i++) {
          let closestItemXTmp = itemsInCategory[i].x + itemsInCategory[i].width;
          closestItemXTmp = CustomDecimals.toNumberFormatted(closestItemXTmp);

          if (closestItemX === null || Math.abs(mouseX - closestItemX) > Math.abs(mouseX - closestItemXTmp)) {
            if (!this.checkExistsElementVertical(closestItemXTmp, movingItemId, movingItemWidth, itemsToIgnore)) {
              closestItemX = closestItemXTmp;
              snapItemAfter = (itemsInCategory[i].x < closestItemXTmp);
              snapToItem = itemsInCategory[i];
            }
          }

          closestItemXTmp = itemsInCategory[i].x - movingItemWidth;
          closestItemXTmp = CustomDecimals.toNumberFormatted(closestItemXTmp);

          if (closestItemX === null || Math.abs(mouseX - closestItemX) > Math.abs(mouseX - closestItemXTmp)) {
            if (!this.checkExistsElementVertical(closestItemXTmp, movingItemId, movingItemWidth, itemsToIgnore)) {
              closestItemX = closestItemXTmp;
              snapItemAfter = (itemsInCategory[i].x < closestItemXTmp);
              snapToItem = itemsInCategory[i];
            }
          }
        }

        if (closestItemX === null) {
          return closestItemX;
        }

        if (sourceItem.front_reversed) {
          this.reverseItemImage(sourceItem, movingItemId, snapItemAfter);
        }

        if (addItemAsChild) {
          this.updateAttachedItems(snapToItem, movingItemId);
        }

        return closestItemX;
      }

      return null;
    },
    getSnapHorizItemPositionX (mouseX, sourceItem, itemIdOnCanvas, addItemAsChild) {
      let itemsInCategoryIDs = this.inventoryFormatted.filter(item => (sourceItem.fitsIn.indexOf(item.id) > -1)).map(item => item.id);
      let itemsInCategory = this.frontViewInventory.filter(item => (itemsInCategoryIDs.indexOf(item.inventoryItemId) > -1));

      if (sourceItem.width === 'flex') {
        let minWidth = this.getProportionalSizeValue(sourceItem.min_width);
        let maxWidth = this.getProportionalSizeValue(sourceItem.max_width);
        let availableSpacesForItem = this.wardrobeAvailableSpaceHoriz.filter(item => item.width >= minWidth && item.width <= maxWidth && item.height);
        itemsInCategory = itemsInCategory.concat(availableSpacesForItem);
      }

      if (itemsInCategory.length) {
        let closestItemX = null;
        let snapToItem = null;

        for (let i = 0; i < itemsInCategory.length; i++) {
          if (i === 0) {
            closestItemX = itemsInCategory[i].x;

            if (itemsInCategory[i].isReversed === false) {
              closestItemX += this.inventoryBoardThicknessOnImg
            }

            snapToItem = itemsInCategory[i];
          } else {
            let closestItemXTmp = itemsInCategory[i].x;

            if (closestItemX === null || Math.abs(mouseX - closestItemX) > Math.abs(mouseX - closestItemXTmp)) {
              closestItemX = closestItemXTmp;

              if (itemsInCategory[i].isReversed === false) {
                closestItemX += this.inventoryBoardThicknessOnImg
              }
              snapToItem = itemsInCategory[i];
            }
          }
        }

        if (addItemAsChild) {
          this.updateAttachedItems(snapToItem, itemIdOnCanvas);
        }
        return { x: closestItemX, parentItem: snapToItem };
      }

      return null;
    },
    getSnapHorizItemPositionY (mouseY, itemToFitIn, movingItemId, movingItemHeight) {
      if ('id' in itemToFitIn && (mouseY < itemToFitIn.y + this.inventoryBoardThicknessOnImg + this.horizItemMinSpaceBetweenOnImg)) {
        mouseY = itemToFitIn.y + this.inventoryBoardThicknessOnImg + this.horizItemMinSpaceBetweenOnImg;
      } else if (!('id' in itemToFitIn) && (mouseY < itemToFitIn.y)) {
        mouseY = itemToFitIn.y
      } else if (mouseY > this.wardrobeFloorPosY - this.horizItemMinSpaceBetweenOnImg - movingItemHeight) {
        mouseY = this.wardrobeFloorPosY - this.horizItemMinSpaceBetweenOnImg - movingItemHeight;
      }

      if (!this.checkExistsElementHorizontal(mouseY, itemToFitIn, movingItemId, movingItemHeight)) {
        return mouseY;
      }

      let itemsToCampare = [];
      if (itemToFitIn.attachedItems) {
        let itemsIdsToCampare = itemToFitIn.attachedItems.filter(ele => ele !== movingItemId);

        if (!itemsIdsToCampare || !itemsIdsToCampare.length) {
          return false;
        }
        itemsToCampare = this.frontViewInventory.filter(ele => (itemsIdsToCampare.indexOf(ele.id) > -1) && (ele.positionType === 'horizontal'));
      } else {
        itemsToCampare = this.frontViewInventory.filter(ele => (ele.x === itemToFitIn.x) && (ele.positionType === 'horizontal'));
      }

      if (!itemsToCampare || !itemsToCampare.length) {
        return null;
      }

      let minDiff = movingItemHeight + this.horizItemMinSpaceBetweenOnImg;
      itemsToCampare.sort((CustomDecimals.sortAscBy('y')));

      if (itemsToCampare.length) {
        let closestItemY = null;

        for (let i = 0; i < itemsToCampare.length; i++) {
          if (i === 0) {
            let closestItemYTmp = itemsToCampare[i].y;

            if (itemsToCampare[i].y < mouseY) {
              closestItemYTmp += itemsToCampare[i].height + this.horizItemMinSpaceBetweenOnImg;
            } else {
              closestItemYTmp -= minDiff;
            }

            closestItemYTmp = CustomDecimals.toNumberFormatted(closestItemYTmp);

            if (!this.checkExistsElementHorizontal(closestItemYTmp, itemToFitIn, movingItemId, movingItemHeight)) {
              closestItemY = closestItemYTmp;
            }
          } else {
            let closestItemYTmp = itemsToCampare[i].y;

            if (itemsToCampare[i].y < mouseY) {
              closestItemYTmp += itemsToCampare[i].height + this.horizItemMinSpaceBetweenOnImg;
            } else {
              closestItemYTmp -= minDiff;
            }

            closestItemYTmp = CustomDecimals.toNumberFormatted(closestItemYTmp);

            if (closestItemY === null || Math.abs(mouseY - closestItemY) > Math.abs(mouseY - closestItemYTmp)) {
              if (!this.checkExistsElementHorizontal(closestItemYTmp, itemToFitIn, movingItemId, movingItemHeight)) {
                closestItemY = closestItemYTmp;
              }
            }
          }
        }

        return closestItemY;
      }
      return null;
    },
    checkExistsElementVertical (itemNewPosX, itemId, itemWidth, itemsToIgnore = null) {
      if ((itemNewPosX < this.inventoryMinXPos) || ((itemNewPosX + itemWidth) >= this.inventoryMinXPos + this.wardrobeImgWidth)) {
        Vue.set(this, 'isInsertable', false);
        return true;
      }

      let itemsToCampare = this.frontViewInventory.filter(ele => (ele.positionType === 'vertical') && (ele.id !== itemId));

      if (itemsToIgnore) {
        itemsToCampare = itemsToCampare.filter(ele => itemsToIgnore.indexOf(ele.id) < 0);
      }

      for (let i = 0; i < itemsToCampare.length; i++) {
        if ((CustomDecimals.toNumberFormatted(itemNewPosX + itemWidth) > CustomDecimals.toNumberFormatted(itemsToCampare[i].x)) && (itemNewPosX < CustomDecimals.toNumberFormatted(itemsToCampare[i].x + itemsToCampare[i].width))) {
          return true;
        }
      }

      return false;
    },
    updateViews (currentItemID, posX, posY = null, parentElement) {
      this.updateFrontView(currentItemID, posX, posY, parentElement);
      this.updateTopView(currentItemID, posX);

      this.updateTopViewItemInCollision();
    },
    updateTopView (currentItemId, posX) {
      if (currentItemId && posX) {
        let correspondingItemIndex = this.topViewInventory.findIndex(item => item.id === currentItemId);

        if (correspondingItemIndex > -1) {
          Vue.set(this.topViewInventory[correspondingItemIndex], 'x', posX);
        }
      } else {
        console.log('niepoprawna wartośc w update top view: ', currentItemId, posX);
      }
    },
    updateFrontView (currentItemId, posX, posY = null, parentElement) {
      if (currentItemId) {
        let correspondingItem = this.frontViewInventory.find(item => item.id === currentItemId);
        let correspondingItemIndex = this.frontViewInventory.findIndex(item => item.id === currentItemId);

        if (correspondingItem) {
          Vue.set(this.frontViewInventory[correspondingItemIndex], 'x', posX);

          if (correspondingItem.positionType === 'horizontal' && parentElement && parentElement.isReversed === false) {
            correspondingItem.x += this.inventoryBoardThicknessOnImg;
          }

          this.$store.commit('updateInventoryItemProp', { stageID: currentItemId, propName: 'posX', newPropValue: this.getProportionalRealXValue(correspondingItem.x) });

          if (posY) {
            Vue.set(this.frontViewInventory[correspondingItemIndex], 'y', posY);
            this.$store.commit('updateInventoryItemProp', { stageID: currentItemId, propName: 'posY', newPropValue: this.getProportionalRealYValue(posY) });
          }

          if (correspondingItem.isFlexWidth) {
            this.$store.commit('updateInventoryItemProp', { stageID: currentItemId, propName: 'width', newPropValue: this.getProportionalRealSizeValue(correspondingItem.width) });
          }

          this.setIfItemHasCollision(correspondingItem);
          correspondingItem.opacity = 1;
        }

        if (correspondingItem.inventoryCategoryId === 2 && correspondingItem.attachedItems.length) {
          let allHorizontalChildren = this.frontViewInventory.filter(item => correspondingItem.attachedItems.indexOf(item.id) > -1 && item.positionType === 'horizontal');

          for (let i = 0; i < allHorizontalChildren.length; i++) {
            this.updateViews(allHorizontalChildren[i].id, posX, null, correspondingItem);
          }
        }
      }
    },
    reverseItemImage (sourceItem, itemId, isReversed) {
      let itemIndex = this.frontViewInventory.findIndex(ele => ele.id === itemId.toString());
      let frontViewItem = this.frontViewInventory[itemIndex];

      // to powinno być tymczasowe
      if (!frontViewItem || !('isReversed' in frontViewItem)) {
        if (this.inventoryInDragIsReversed === isReversed) {
          return;
        }
        Vue.set(this, 'inventoryInDragIsReversed', isReversed);
        return;
      }

      if (frontViewItem.isReversed === isReversed) {
        return;
      }

      frontViewItem.isReversed = isReversed;
      let imageSrc = isReversed ? sourceItem.front_reversed : sourceItem.front
      let itemFrontImg = new window.Image();
      itemFrontImg.src = imageSrc;
      frontViewItem.src = imageSrc;
      frontViewItem.image = itemFrontImg;

      this.$store.commit('updateInventoryItemProp', { stageID: itemId, propName: 'isReversed', newPropValue: frontViewItem.isReversed });
    },
    checkItemOutOfBound (mouseX, mouseY, dragItemX, dragItemWidth) {
      if (dragItemX < 0 || (dragItemX + dragItemWidth) > this.canvasWidth) {
        return true;
      }

      if (mouseX < this.itemOutOfBoundsBorders.leftBorder || mouseX > this.itemOutOfBoundsBorders.rightBorder) {
        return true;
      }

      if (mouseY < this.itemOutOfBoundsBorders.topBorder || mouseY > this.itemOutOfBoundsBorders.bottomBorder) {
        return true;
      }

      return false;
    },
    removeItemFromScene (idOnScene) {
      let itemInScene = this.frontViewInventory.find(item => item.id === idOnScene.toString());

      let attachedItemsChain = this.getAllAttachedItemsInChain(itemInScene);

      if (itemInScene) {
        for (let i = 0; i < attachedItemsChain.chain.length; i++) {
          let attachedItemID = attachedItemsChain.chain[i];
          let attachedItem = this.frontViewInventory.find(item => item.id === attachedItemID.toString());

          if (attachedItem) {
            let attachedItemInventoryID = attachedItem.inventoryItemId;
            let attachedItemIndex = this.frontViewInventory.findIndex(item => item.id === attachedItemID.toString());
            this.frontViewInventory.splice(attachedItemIndex, 1);
            this.selectedInventory.splice(this.selectedInventory.indexOf(attachedItemInventoryID), 1);
            this.$store.commit('removeInventoryItem', attachedItemID);

            attachedItemIndex = this.topViewInventory.findIndex(item => item.id === attachedItemID.toString());

            if (attachedItemIndex >= 0) {
              this.topViewInventory.splice(attachedItemIndex, 1);
            }
          }
        }

        let itemIndex = this.frontViewInventory.findIndex(item => item.id === idOnScene.toString());
        this.frontViewInventory.splice(itemIndex, 1);
        this.selectedInventory.splice(this.selectedInventory.indexOf(itemInScene.inventoryItemId), 1);
        this.$store.commit('removeInventoryItem', idOnScene);

        let currentParent = this.frontViewInventory.find(item => item.attachedItems.indexOf(idOnScene) > -1);

        if (currentParent) {
          let indexOfChildID = currentParent.attachedItems.indexOf(idOnScene);
          currentParent.attachedItems.splice(indexOfChildID, 1);
          this.$store.commit('removeInventoryAttachedItem', { stageID: currentParent.id, childID: idOnScene });
        }
      }

      let itemIndex = this.topViewInventory.findIndex(item => item.id === idOnScene.toString());

      if (itemIndex >= 0) {
        this.topViewInventory.splice(itemIndex, 1);
      }

      this.updateTopViewItemInCollision();
      this.updatePrice();
    },
    removeInvalidUnattachedItems () {
      let unattachedItems = this.frontViewHorizontalItems.filter(item => item.isNotAttached === true);
      for (let i = 0; i < unattachedItems.length; i++) {
        let gapForItem = this.wardrobeAvailableSpaceHoriz.find(item => item.x === unattachedItems[i].x && item.width === unattachedItems[i].width);

        if (!gapForItem) {
          this.removeItemFromScene(unattachedItems[i].id);
        }
      }
    },
    checkExistsElementHorizontal (itemNewPosY, itemToFitIn, movingItemId, movingItemHeight) {
      let isAboveItemToFitIn = false;
      if ('id' in itemToFitIn) {
        isAboveItemToFitIn = !!(itemNewPosY < itemToFitIn.y + this.inventoryBoardThicknessOnImg + this.horizItemMinSpaceBetweenOnImg);
      } else {
        isAboveItemToFitIn = !!(itemNewPosY < itemToFitIn.y);
      }
      let isBelowItemToFitIn = !!(itemNewPosY > this.wardrobeFloorPosY - this.horizItemMinSpaceBetweenOnImg - movingItemHeight);
      if (isAboveItemToFitIn || isBelowItemToFitIn) {
        Vue.set(this, 'isInsertable', false);
        return true;
      }

      let itemsToCampare = [];

      if (itemToFitIn.attachedItems) {
        let itemsIdsToCampare = itemToFitIn.attachedItems.filter(ele => ele !== movingItemId);

        if (!itemsIdsToCampare || !itemsIdsToCampare.length) {
          return false;
        }
        itemsToCampare = this.frontViewInventory.filter(ele => (itemsIdsToCampare.indexOf(ele.id) > -1) && (ele.positionType === 'horizontal'));
      } else {
        itemsToCampare = this.frontViewInventory.filter(ele => (ele.x === itemToFitIn.x) && (ele.positionType === 'horizontal'));
      }

      if (!itemsToCampare.length) {
        return false;
      }

      for (let i = 0; i < itemsToCampare.length; i++) {
        let minY = CustomDecimals.toNumberFormatted(itemsToCampare[i].y + itemsToCampare[i].height + this.horizItemMinSpaceBetweenOnImg);
        let maxY = CustomDecimals.toNumberFormatted(itemsToCampare[i].y - this.horizItemMinSpaceBetweenOnImg - movingItemHeight);
        if (itemNewPosY < minY && itemNewPosY > maxY) {
          return true;
        }
      }
      return false;
    },
    getNewItemPosX (item, itemIdOnCanvas) {
      if (!this.selectedInventory.length) {
        return this.wardrobeAvailableSpaceHoriz[0].x + (this.wardrobeAvailableSpaceHoriz[0].width / 2);
      }

      if (item.category_id === 4) {
        let freeSpaceClone = JSON.parse(JSON.stringify(this.wardrobeAvailableSpaceHoriz));
        freeSpaceClone.sort((CustomDecimals.sortAscBy('width')));
        let last = freeSpaceClone.pop();
        return last.x + (last.width / 2);
      }

      let posX = null;

      for (let i = 0; i < this.wardrobeAvailableSpaceHoriz.length; i++) {
        if (this.wardrobeAvailableSpaceHoriz[i].width >= item.widthOnImg) {
          posX = this.getSnapPositionX(this.wardrobeAvailableSpaceHoriz[i].x, item, itemIdOnCanvas, item.widthOnImg, true);

          if (posX) {
            return posX;
          }
        }
      }

      return posX;
    },
    updateAttachedItems (newParent, childIdOnCanvas) {
      if (!childIdOnCanvas) {
        console.log('BRAK ID');
        return;
      }

      let currentParent = this.frontViewInventory.find(item => item.attachedItems.indexOf(childIdOnCanvas) > -1);

      if (currentParent && currentParent.id === newParent.id) {
        return;
      }

      if (currentParent) {
        let indexOfChildID = currentParent.attachedItems.indexOf(childIdOnCanvas);
        currentParent.attachedItems.splice(indexOfChildID, 1);
        this.$store.commit('removeInventoryAttachedItem', { stageID: currentParent.id, childID: childIdOnCanvas });
      }

      if (newParent && ('id' in newParent)) {
        newParent.attachedItems.push(childIdOnCanvas);
        this.$store.commit('addInventoryAttachedItem', { stageID: newParent.id, childID: childIdOnCanvas });
      }

      let child = this.frontViewHorizontalItems.find(item => item.id === childIdOnCanvas);
      if (child) {
        if (newParent && 'id' in newParent) {
          child.isNotAttached = false;
          return;
        }

        child.isNotAttached = true;
      }
    },
    getAvailableSpaceInModule (module, horizonaltItems) {
      let availableSpaceArray = [];

      horizonaltItems.sort((CustomDecimals.sortAscBy('y')));

      let wardrobeTopY = CustomDecimals.toNumberFormatted(module.y + this.inventoryBoardThicknessOnImg);
      let wardrobeBottomY = CustomDecimals.toNumberFormatted(module.y + module.height);

      for (let i = 0; i < horizonaltItems.length; i++) {
        if (i === 0) {
          availableSpaceArray.push({
            height: CustomDecimals.toNumberFormatted(Math.abs(wardrobeTopY - horizonaltItems[i].y), 2),
            y: wardrobeTopY
          });
        }

        if (i === horizonaltItems.length - 1) {
          availableSpaceArray.push({
            height: CustomDecimals.toNumberFormatted(Math.abs((horizonaltItems[i].y + horizonaltItems[i].height) - wardrobeBottomY), 2),
            y: CustomDecimals.toNumberFormatted(horizonaltItems[i].y + horizonaltItems[i].height, 2)
          });
        } else {
          availableSpaceArray.push({
            height: CustomDecimals.toNumberFormatted(Math.abs((horizonaltItems[i].y + horizonaltItems[i].height) - (horizonaltItems[i + 1].y)), 2),
            y: CustomDecimals.toNumberFormatted(horizonaltItems[i].y + horizonaltItems[i].height, 2)
          });
        }
      }

      return availableSpaceArray;
    },
    updateAttachedItemsPosition (item, attachedItemsChain) {
      if (!item.attachedItems.length) {
        return;
      }

      let itemsToIgnore = [...attachedItemsChain];

      for (let i = 0; i < attachedItemsChain.length; i++) {
        setTimeout(() => {
          let wardrobeItem = this.frontViewInventory.find(ele => ele.id === attachedItemsChain[i]);
          let sourceItem = this.inventoryFormatted.find(ele => ele.id === wardrobeItem.inventoryItemId);

          let posX = wardrobeItem.x;
          posX = this.getItemXOnCanvas(posX, sourceItem, wardrobeItem.id, wardrobeItem.width, true, itemsToIgnore);

          if (!posX) {
            console.log('wrong new posX value', wardrobeItem);
          }

          let indexToRemove = itemsToIgnore.indexOf(wardrobeItem.id);
          itemsToIgnore.splice(indexToRemove, 1);

          this.updateViews(wardrobeItem.id, posX, wardrobeItem.y, item);
        }, 100)
      }
    },
    getNewHorizItemPosY (itemToFitIn, item) {
      let attachedItemsHorizontal = [];

      if (!('id' in itemToFitIn) && item.width === 'flex') {
        attachedItemsHorizontal = this.frontViewHorizontalItems.filter(ele => ele.isNotAttached === true && ele.x === itemToFitIn.x);
      } else {
        attachedItemsHorizontal = this.frontViewInventory.filter(ele => itemToFitIn.attachedItems.indexOf(ele.id) > -1 && ele.positionType === 'horizontal');
      }

      if (!attachedItemsHorizontal || !attachedItemsHorizontal.length) {
        return CustomDecimals.toNumberFormatted(Number(itemToFitIn.y) + (Number(itemToFitIn.height) / 2));
      }

      let availableSpace = this.getAvailableSpaceInModule(itemToFitIn, attachedItemsHorizontal);
      availableSpace.sort((CustomDecimals.sortAscBy('height')));
      let minSpaceNeeded = (this.horizItemMinSpaceBetweenOnImg * 2) + item.heightOnImg;
      let last = availableSpace.pop();

      if (last.height < minSpaceNeeded) {
        return null;
      }

      return CustomDecimals.toNumberFormatted(last.y + ((last.height / 2) - (item.heightOnImg / 2)));
    },
    getNewHorizItemPosition (item, itemIdOnCanvas) {
      let newPosX = null;
      let newPosY = null;
      let itemsInCategoryIDs = this.inventoryFormatted.filter(ele => (item.fitsIn.indexOf(ele.id) > -1)).map(ele => ele.id);
      let itemsInCategory = this.frontViewInventory.filter(ele => (itemsInCategoryIDs.indexOf(ele.inventoryItemId) > -1));

      if (item.width === 'flex') {
        let minWidth = this.getProportionalSizeValue(item.min_width);
        let maxWidth = this.getProportionalSizeValue(item.max_width);
        let availableSpacesForItem = this.wardrobeAvailableSpaceHoriz.filter(ele => ele.width >= minWidth && ele.width <= maxWidth && ele.height);
        itemsInCategory = itemsInCategory.concat(availableSpacesForItem);
      }

      if (itemsInCategory.length) {
        for (let i = 0; i < itemsInCategory.length; i++) {
          let isNotAttachedValue = false;
          if (i === 0) {
            let closestItemYTmp = this.getNewHorizItemPosY(itemsInCategory[i], item);
            if (closestItemYTmp) {
              newPosX = itemsInCategory[i].x;
              newPosY = closestItemYTmp;
              let newWidthVal = itemsInCategory[i].width;

              if (!('id' in itemsInCategory[i])) {
                isNotAttachedValue = true;
              } else {
                if (!itemsInCategory[i].isReversed) {
                  newPosX += this.inventoryBoardThicknessOnImg
                }

                newWidthVal = itemsInCategory[i].width - this.inventoryBoardThicknessOnImg
              }

              this.updateAttachedItems(itemsInCategory[i], itemIdOnCanvas);
              return {
                x: newPosX,
                y: newPosY,
                newWidth: newWidthVal,
                isNotAttached: isNotAttachedValue
              };
            }
          } else {
            let closestItemYTmp = this.getNewHorizItemPosY(itemsInCategory[i], item);
            if (closestItemYTmp) {
              newPosX = itemsInCategory[i].x;
              newPosY = closestItemYTmp;
              let newWidthVal = itemsInCategory[i].width;

              if (!('id' in itemsInCategory[i])) {
                isNotAttachedValue = true;
              } else {
                if (!itemsInCategory[i].isReversed) {
                  newPosX += this.inventoryBoardThicknessOnImg
                }

                newWidthVal = itemsInCategory[i].width - this.inventoryBoardThicknessOnImg
              }

              this.updateAttachedItems(itemsInCategory[i], itemIdOnCanvas);
              return {
                x: newPosX,
                y: newPosY,
                newWidth: newWidthVal,
                isNotAttached: isNotAttachedValue
              };
            }
          }
        }
      }

      return { x: null, y: null };
    },
    handleItemClick (e, itemID, inventoryCategoryId) {
      this.selectItem(itemID, inventoryCategoryId);
    },
    selectItem (itemID, inventoryCategoryId) {
      this.deselectItem();
      Vue.nextTick(() => {
        let item = this.frontViewInventory.find(ele => ele.id === itemID);
        let itemInTopView = this.topViewInventory.find(ele => ele.id === itemID);
        Vue.set(this, 'clickedItemId', itemID);
        Vue.set(this, 'selectedSourceItem', this.inventoryFormatted.find(ele => ele.id === item.inventoryItemId));

        if (item) {
          if (inventoryCategoryId === 2) {
            item.fill = this.selectedItemFillColor;
          } else {
            item.strokeWidth = 7;
          }
        }

        if (itemInTopView) {
          itemInTopView.fill = this.selectedItemFillColor;
        }
      })
    },
    deselectItem () {
      if (this.clickedItemId) {
        let item = this.frontViewInventory.find(ele => ele.id === this.clickedItemId);
        let itemInTopView = this.topViewInventory.find(ele => ele.id === this.clickedItemId);
        if (item) {
          if (!item.isInCollision) {
            item.fill = '';
          }

          item.strokeWidth = 0;
        }

        if (itemInTopView) {
          if (itemInTopView.isInCollision && this.includeDoorCollisions) {
            itemInTopView.fill = this.collisionColor;
          } else {
            itemInTopView.fill = itemInTopView.baseFill;
          }
        }
        Vue.set(this, 'clickedItemId', 0);
        Vue.set(this, 'selectedSourceItem', null);
      }
    },
    removeItem () {
      this.removeItemFromScene(this.clickedItemId);
      this.deselectItem();
    },
    away () {
      this.deselectItem();
    },
    clearScene () {
      Vue.set(this, 'selectedInventory', []);
      Vue.set(this, 'frontViewInventory', []);
      Vue.set(this, 'topViewInventory', []);
      this.updateTopViewItemInCollision();
      this.$store.commit('resetInventory');
      this.updatePrice();
    },
    keyAction (e) {
      if (!this.clickedItemId) {
        return;
      }
      if (e.code === 'Backspace' || e.code === 'Delete') {
        e.preventDefault();
        e.stopPropagation();
        this.removeItem();
        return;
      }
      if (e.code === 'Escape') {
        this.deselectItem();
      }
    },
    getAllVerticalAttachedItemsInChain (topElement) {
      let allAttachedItemsArray = { chain: [] };
      return this.getVerticalAttachedItemsChain(topElement, allAttachedItemsArray);
    },
    getVerticalAttachedItemsChain(element, array) {
      let onlyVertical = element.attachedItems.filter(ele => ele.includes('vertical'));
      if (!onlyVertical.length) {
        return array;
      }

      array.chain = array.chain.concat(onlyVertical);

      for (let i = 0; i < onlyVertical.length; i++) {
        let childElement = this.frontViewInventory.find(ele => ele.id === onlyVertical[i]);
        this.getVerticalAttachedItemsChain(childElement, array);
      }

      return array;
    },
    getAllAttachedItemsInChain (topElement) {
      let allAttachedItemsArray = { chain: [] };
      return this.getAttachedItemsChain(topElement, allAttachedItemsArray);
    },
    getAttachedItemsChain(element, array) {
      if (!element || !element.attachedItems.length) {
        return array
      }
      let allItems = element.attachedItems;

      array.chain = array.chain.concat(allItems);

      for (let i = 0; i < allItems.length; i++) {
        let childElement = this.frontViewInventory.find(ele => ele.id === allItems[i]);
        this.getAttachedItemsChain(childElement, array);
      }

      return array;
    },
    updatePrice () {
      this.$bus.$emit('update-wardrobe-price');
    },
    windowResize () {
      Vue.set(this, 'sceneInitiated', false);
      this.isResizeComplete = false;
      this.resizeTime = new Date();
      this.resizeInProgress = true;
      document.querySelector('.main-content').classList.add('is-repainting');

      if (this.resizeTimeout === false) {
        this.resizeTimeout = true;
        setTimeout(this.resizeend, this.resizeTimeDelta);
      }
    },
    resizeend() {
      if (new Date() - this.resizeTime < this.resizeTimeDelta) {
        setTimeout(this.resizeend, this.resizeTimeDelta);
      } else {
        // store last resize to avoid multiple repaints after code changes
        let lastResize = parseInt(document.body.getAttribute('data-last-resize-time'), 10);
        // eslint-disable-next-line
        if (isNaN(lastResize) || (new Date() - lastResize) > 1000) {
          this.resizeTimeout = false;
          this.resizeInProgress = false;
          this.initScene();
          document.querySelector('.main-content').classList.remove('is-repainting');
          document.body.setAttribute('data-last-resize-time', +new Date());
        }
      }
    },
    initScene () {
      this.resizeCanvas();
      Vue.nextTick(() => {
        this.addExistingItemsToWardrobe();
        this.setIfItemHasCollision();
        this.updateTopViewItemInCollision();
      });
    },
    resizeCanvas () {
      if (this.$refs['stage-wrapper']) {
        this.canvasWidth = this.$refs['stage-wrapper'].offsetWidth;

        if (this.$refs['stage-wrapper'].offsetWidth >= 1090) {
          this.canvasHeight = 740;
        } else {
          this.canvasHeight = 500;
        }

        let wardrobeImgHeightMax = this.canvasHeight - this.yOffset - this.wallImgWidth - 50;
        let wardrobeImgWidthMax = this.canvasWidth - ((2 * this.wallImgWidth) + this.xOffset + 10);

        Vue.nextTick(() => {
          let minProportion = Math.min(Number(wardrobeImgWidthMax) / Number(this.data.width), Number(wardrobeImgHeightMax) / Number(this.data.height));

          this.wardrobeImgWidth = CustomDecimals.toNumberFormatted(this.data.width * minProportion, 2);
          this.wardrobeImgHeight = CustomDecimals.toNumberFormatted(this.data.height * minProportion, 2);

          // For debugging
          if (this.wardrobeImgWidth > wardrobeImgWidthMax) {
            console.warn('wardrobe width bigger than max');
          }
          if (this.wardrobeImgHeight > wardrobeImgHeightMax) {
            console.warn('wardrobe height bigger than max');
          }
          if (CustomDecimals.toNumberFormatted(Number(this.wardrobeImgHeight) / Number(this.data.height)) !== CustomDecimals.toNumberFormatted(Number(this.wardrobeImgWidth) / Number(this.data.width))) {
            console.warn('proportions do not match', CustomDecimals.toNumberFormatted(Number(this.wardrobeImgHeight) / Number(this.data.height)), CustomDecimals.toNumberFormatted(Number(this.wardrobeImgWidth) / Number(this.data.width)));
          }

          let newMinCanvasHeight = this.wardrobeImgHeight + this.yOffset + this.wallImgWidth + 50;
          if (this.canvasHeight > newMinCanvasHeight) {
            this.canvasHeight = newMinCanvasHeight
          }

          let newMinCanvasWidth = this.wardrobeImgWidth + (2 * this.wallImgWidth) + this.xOffset + 10;
          if (this.canvasWidth > newMinCanvasWidth) {
            this.canvasWidth = newMinCanvasWidth
          }
          this.isResizeComplete = true;
        });
      }
    },
    addExistingItemsToWardrobe () {
      this.frontViewInventory.splice(0, this.frontViewInventory.length);
      this.topViewInventory.splice(0, this.topViewInventory.length);

      let itemsInStoreCopy = JSON.parse(JSON.stringify(this.$store.getters['getWardrobeInventory']));
      for (let i = 0; i < itemsInStoreCopy.length; i++) {
        this.addItem(itemsInStoreCopy[i].id, itemsInStoreCopy[i]);
      }
      Vue.nextTick(() => {
        Vue.set(this, 'sceneInitiated', true);
      });
    },
    updateTopViewItemInCollision () {
      for (let i = 0; i < this.frontViewInventory.length; i++) {
        let correspondingItem = this.frontViewInventory[i];
        let itemInTopView = this.topViewInventory.find(item => item.id === correspondingItem.id);
        if (itemInTopView && correspondingItem.attachedItems && correspondingItem.attachedItems.length) {
          let hasCollision = false;

          for (let j = 0; j < correspondingItem.attachedItems.length; j++) {
            let correspondingChildItem = this.frontViewInventory.find(item => item.id === correspondingItem.attachedItems[j]);
            if (correspondingChildItem && ('isPullOut' in correspondingChildItem) && correspondingChildItem.isPullOut && correspondingChildItem.isInCollision) {
              hasCollision = true;
              break;
            }
          }

          if (this.includeDoorCollisions) {
            this.markItemHasCollision(itemInTopView, hasCollision);
          }
        } else if (itemInTopView && this.includeDoorCollisions) {
          this.markItemHasCollision(itemInTopView, false);
        }
      }
    },
    markItemHasCollision (itemToMark, hasCollision) {
      itemToMark.isInCollision = hasCollision;
      if (this.clickedItemId === itemToMark.id) {
        itemToMark.fill = this.selectedItemFillColor;
        return;
      }

      if (hasCollision) {
        itemToMark.fill = this.collisionColor;
        return;
      }

      itemToMark.fill = itemToMark.baseFill;
    },
    setIfItemHasCollision (itemTocheck = null) {
      if (!this.includeDoorCollisions) {
        return;
      }
      let itemsToCheck = [];
      if (itemTocheck) {
        if (!itemTocheck.isPullOut) {
          return;
        }
        itemsToCheck.push(itemTocheck);
      } else {
        itemsToCheck = this.frontViewInventory.filter(item => item.isPullOut);
      }

      for (let i = 0; i < itemsToCheck.length; i++) {
        let elementWidth = itemsToCheck[i].width;
        let elementPosX = itemsToCheck[i].x;
        let connectionPointInCollision = this.wardrobeImgDoorsConnectionPoints.find(item => (item.x >= elementPosX && item.x <= elementPosX + elementWidth) || (item.x + item.width >= elementPosX && item.x + item.width <= elementPosX + elementWidth));

        if (connectionPointInCollision) {
          itemsToCheck[i].fill = this.collisionColor;
          itemsToCheck[i].isInCollision = true;
        } else {
          itemsToCheck[i].fill = '';
          itemsToCheck[i].isInCollision = false;
        }
      }
    },
    goToSummary () {
      if (this.existisItemWidthCollision) {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        return;
      }
      this.updatePrice();
      this.saveCanvasImg();
      setTimeout(() => {
        this.$router.push('/order-summary');
      }, 250);
    },
    goBack () {
      if (this.data.isWalkInCloset) {
        this.$router.push('/basic-information');
        return;
      }

      this.$router.push('/materials-selection');
    },
    saveCanvasImg () {
      let stageFronViewRef = this.$refs['stage-front'].getNode();
      window.pandoraCanvasScreenshotFrontView = stageFronViewRef.toDataURL();

      let stageTopViewRef = this.$refs['stage-top'].getNode();
      window.pandoraCanvasScreenshotTopView = stageTopViewRef.toDataURL();
    },
    getObjectForHorizDimensions (itemX, itemWidth, itemRealWidth) {
      return {
        x: CustomDecimals.toNumberFormatted(itemX),
        width: CustomDecimals.toNumberFormatted(itemWidth),
        realWidth: CustomDecimals.toNumberFormatted(itemRealWidth)
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('add-item-to-wardrobe', this.addItem);
    window.removeEventListener('keydown', this.keyAction);
    window.removeEventListener('resize', this.windowResize, false);
  }
};
</script>
