var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"view-content"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"main-content basic-information pb-5"},[(_vm.loaded)?_c('validation-observer',{ref:"basic-info-form",staticClass:"columns-wrapper",attrs:{"tag":"form"}},[_c('div',{staticClass:"column column-left"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":("required|min_value:" + _vm.minWidth + "|max_value:" + _vm.maxWidth),"name":"wardrobe-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{
                    'form-input': true,
                    'is-invalid': errors.length
                  },attrs:{"for":"wardrobe-width"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Bredde")]),_c('c-input',{attrs:{"type":"number","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.width),callback:function ($$v) {_vm.$set(_vm.data, "width", $$v)},expression:"data.width"}}),_c('span',{staticClass:"form-input-appendix"},[_vm._v("mm")])],1),_c('div',{staticClass:"field-instructions"},[_vm._v(" Bredden skal være imellem "+_vm._s(_vm.minWidth)+"mm og "+_vm._s(_vm.maxWidth)+"mm. ")])]}}],null,false,2225366099)})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":("required|min_value:" + _vm.minHeight + "|max_value:" + _vm.maxHeight),"name":"wardrobe-height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{class:{
                    'form-input': true,
                    'is-invalid': errors.length
                  },attrs:{"for":"wardrobe-height"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Højde")]),_c('c-input',{attrs:{"type":"number","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.height),callback:function ($$v) {_vm.$set(_vm.data, "height", $$v)},expression:"data.height"}}),_c('span',{staticClass:"form-input-appendix"},[_vm._v("mm")])],1),_c('div',{staticClass:"field-instructions"},[_vm._v(" Højden skal være imellem "+_vm._s(_vm.minHeight)+"mm og "+_vm._s(_vm.maxHeight)+"mm. ")])]}}],null,false,772151923)})],1),(!_vm.data.isWalkInCloset)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"wardrobe-use-impact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"form-input",attrs:{"for":"wardrobe-use-impact"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Brug Anslag?")]),_c('v-select',{attrs:{"clearable":false,"disabled":!_vm.isImpactEnabled,"options":_vm.yesNoOptions,"reduce":function (item) { return item.value; },"searchable":false,"placeholder":"","label":"label"},model:{value:(_vm.data.useImpact),callback:function ($$v) {_vm.$set(_vm.data, "useImpact", $$v)},expression:"data.useImpact"}})],1)]}}],null,false,2549507955)})],1):_vm._e(),(_vm.isLeftStrokeAdjustmentEnabled)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"","name":"wardrobe-left-stroke-adjustment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"form-input",attrs:{"for":"wardrobe-left-stroke-adjustment"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Venstre anslagtilpasning")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.yesNoOptions,"reduce":function (item) { return item.value; },"searchable":false,"placeholder":"","label":"label"},model:{value:(_vm.data.leftStrokeAdjustment),callback:function ($$v) {_vm.$set(_vm.data, "leftStrokeAdjustment", $$v)},expression:"data.leftStrokeAdjustment"}})],1)]}}],null,false,3066494025)})],1):_vm._e(),(_vm.isLeftEndAdjustmentEnabled)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"","name":"wardrobe-left-end-adjustment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"form-input",attrs:{"for":"wardrobe-left-end-adjustment"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Venstre gavltilpasning")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.yesNoOptions,"reduce":function (item) { return item.value; },"searchable":false,"placeholder":"","label":"label"},model:{value:(_vm.data.leftEndAdjustment),callback:function ($$v) {_vm.$set(_vm.data, "leftEndAdjustment", $$v)},expression:"data.leftEndAdjustment"}})],1)]}}],null,false,72535459)})],1):_vm._e(),(_vm.isRightStrokeAdjustmentEnabled)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"","name":"wardrobe-right-stroke-adjustment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"form-input",attrs:{"for":"wardrobe-right-stroke-adjustment"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Højre anslagtilpasning")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.yesNoOptions,"reduce":function (item) { return item.value; },"searchable":false,"placeholder":"","label":"label"},model:{value:(_vm.data.rightStrokeAdjustment),callback:function ($$v) {_vm.$set(_vm.data, "rightStrokeAdjustment", $$v)},expression:"data.rightStrokeAdjustment"}})],1)]}}],null,false,280776873)})],1):_vm._e(),(_vm.isRightEndAdjustmentEnabled)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"","name":"wardrobe-right-end-adjustment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"form-input",attrs:{"for":"wardrobe-right-end-adjustment"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Højre gavltilpasning")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.yesNoOptions,"reduce":function (item) { return item.value; },"searchable":false,"placeholder":"","label":"label"},model:{value:(_vm.data.rightEndAdjustment),callback:function ($$v) {_vm.$set(_vm.data, "rightEndAdjustment", $$v)},expression:"data.rightEndAdjustment"}})],1)]}}],null,false,857047299)})],1):_vm._e(),(!_vm.data.isWalkInCloset)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":_vm.isGableEnabled ? 'required' : '',"name":"wardrobe-gable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"form-input",attrs:{"for":"wardrobe-gable"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Gavl")]),_c('v-select',{class:{ 'is-invalid': errors.length },attrs:{"clearable":false,"disabled":!_vm.isGableEnabled,"name":"wardrobe-gable","options":_vm.gableOptions,"reduce":function (item) { return item.id; },"searchable":false,"placeholder":"Vælg galv","label":"name"},model:{value:(_vm.data.gable),callback:function ($$v) {_vm.$set(_vm.data, "gable", $$v)},expression:"data.gable"}})],1)]}}],null,false,2441874101)})],1):_vm._e()]),(!_vm.data.isWalkInCloset)?_c('div',{staticClass:"column column-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('validation-provider',{staticClass:"flex flex-column",attrs:{"rules":"required","name":"wardrobe-position","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"wardrobe-positions flex flex-wrap"},_vm._l((_vm.wardrobePositions),function(position,index){return _c('c-form-radio',{key:index,class:{ 'is-invalid': errors.length },attrs:{"withImage":true,"value":position.id},model:{value:(_vm.data.wardrobePosition),callback:function ($$v) {_vm.$set(_vm.data, "wardrobePosition", $$v)},expression:"data.wardrobePosition"}},[_c('img',{staticClass:"position-image",attrs:{"src":position.image,"alt":'Placering - ' + position.name}}),_c('span',{staticClass:"c-form-radio-label"},[_vm._v(_vm._s(position.name))])])}),1)]}}],null,false,3420756675)})],1)]):_vm._e()]):_vm._e(),(_vm.loaded)?_c('wardrobe-summary',{attrs:{"current-height":Number(_vm.data.height),"current-gable":this.data.gable,"current-use-impact":+_vm.data.useImpact,"current-wardrobe-position":_vm.data.wardrobePosition,"current-width":Number(_vm.data.width),"show-doors":false,"show-doors-count":false}}):_vm._e()],1)])]),_c('div',{staticClass:"wrapper-narrow"},[_c('div',{staticClass:"buttons-bar"},[_c('c-button',{staticClass:"mr-auto",attrs:{"theme":'secondary'},on:{"click":function($event){$event.preventDefault();return _vm.submitForm('prev')}}},[_vm._v(" Tilbage ")]),_c('c-button',{staticClass:"ml-auto",attrs:{"theme":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_vm._v(" Videre ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }