import { email } from 'vee-validate/dist/rules';

export default function addVeeValidateRules (VeeValidateExtend) {
  VeeValidateExtend('required', {
    validate: value => value !== null && String(value).trim() !== '',
    computesRequired: true,
    message: 'Dette felt er påkrævet'
  });

  VeeValidateExtend('email', {
    ...email,
    message: 'Indtast venligst en gyldig e-mail adresse'
  });

  VeeValidateExtend('min', {
    params: ['min'],
    validate: (value, { min }) => value.length >= min,
    message: (field, params) => {
      if (params.min === 1) {
        return 'This field must contain at least one character';
      }

      return 'This field must contain at least ' + params.min + ' characters';
    }
  });

  VeeValidateExtend('max', {
    params: ['max'],
    validate: (value, { max }) => value.length <= max,
    message: (field, params) => {
      if (params.max === 1) {
        return 'This field may contain a maximum of one character';
      }

      return 'This field may contain a maximum of ' + params.max + ' characters';
    }
  });

  VeeValidateExtend('min_value', {
    params: ['minValue'],
    validate: (value, { minValue }) => parseFloat(value) >= parseFloat(minValue),
    message: (field, params) => 'The value cannot be less than ' + params.minValue
  });

  VeeValidateExtend('max_value', {
    params: ['maxValue'],
    validate: (value, { maxValue }) => parseFloat(value) <= parseFloat(maxValue),
    message: (field, params) => 'The value cannot be greater than ' + params.maxValue
  });

  VeeValidateExtend('postal-code', {
    params: ['postal-code'],
    validate: value => value.match(/(^[0-9]{5}$)|(^[0-9]{2}-[0-9]{3}$)/),
    message: 'This field must have a format "00-000" or "00000"'
  });

  VeeValidateExtend('is-checked', {
    params: ['is-checked'],
    validate: value => value === 1 || value === true,
    message: 'Dette felt skal være markeret for at fortsætted'
  });
};
