var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"view-content"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"main-content place-order pb-5"},[_c('div',{staticClass:"card full-width relative"},[_c('h2',{staticClass:"card-heading"},[_vm._v("Bestil Garderobe")]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"place-order-form",staticClass:"columns-wrapper",attrs:{"tag":"form"}},[_c('div',{staticClass:"column column-left"},[_c('h2',{staticClass:"mt-1"},[_vm._v("Faktureringsadresse")]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"name"}},[_c('span',{staticClass:"input-name"},[_vm._v("Navn")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"email"}},[_c('span',{staticClass:"input-name"},[_vm._v("Email")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{staticClass:"ml-2 ml-md-0 full-width order-form-field",attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"phone"}},[_c('span',{staticClass:"input-name"},[_vm._v("Telefon")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"address-1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"address-1"}},[_c('span',{staticClass:"input-name"},[_vm._v("Adresse")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.address_1),callback:function ($$v) {_vm.$set(_vm.data, "address_1", $$v)},expression:"data.address_1"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{staticClass:"ml-2 ml-md-0 full-width order-form-field",attrs:{"name":"address-2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"address-2"}},[_c('span',{staticClass:"input-name"},[_vm._v("Adresse fortsat")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.address_2),callback:function ($$v) {_vm.$set(_vm.data, "address_2", $$v)},expression:"data.address_2"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"postcode"}},[_c('span',{staticClass:"input-name"},[_vm._v("Postnr")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.postcode),callback:function ($$v) {_vm.$set(_vm.data, "postcode", $$v)},expression:"data.postcode"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{staticClass:"ml-2 ml-md-0 full-width order-form-field",attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"city"}},[_c('span',{staticClass:"input-name"},[_vm._v("By")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.city),callback:function ($$v) {_vm.$set(_vm.data, "city", $$v)},expression:"data.city"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"order-form-label",attrs:{"for":"country"}},[_c('span',{staticClass:"input-name"},[_vm._v("Land")]),_c('c-input',{attrs:{"type":"text","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.country),callback:function ($$v) {_vm.$set(_vm.data, "country", $$v)},expression:"data.country"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"mt-2",attrs:{"name":"same_delivery-address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('c-checkbox',{attrs:{"is-small":true,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.same_delivery_address),callback:function ($$v) {_vm.$set(_vm.data, "same_delivery_address", $$v)},expression:"data.same_delivery_address"}},[_vm._v(" Samme adresse som leveringsadressen ")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"terms-and-conditions","rules":"required|is-checked"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('c-checkbox',{attrs:{"is-small":true,"state":errors.length ? 'invalid' : null},model:{value:(_vm.terms_and_conditions),callback:function ($$v) {_vm.terms_and_conditions=$$v},expression:"terms_and_conditions"}},[_vm._v(" Jeg har læst og accepterer "),_c('a',{attrs:{"href":"documents/terms_and_conditions.pdf","target":"_blank","rel":"nofollow noopener noreferrer"}},[_vm._v("handelsbetingelserne")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"column column-right"},[_c('h2',{staticClass:"mt-1"},[_vm._v("Leveringsadresse")]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width",attrs:{"name":"delivery-name","rules":_vm.data.same_delivery_address ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-name"}},[_c('span',{staticClass:"input-name"},[_vm._v("Navn")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_name),callback:function ($$v) {_vm.$set(_vm.data, "delivery_name", $$v)},expression:"data.delivery_name"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"delivery-email","rules":_vm.data.same_delivery_address ? '' : 'required|email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-email"}},[_c('span',{staticClass:"input-name"},[_vm._v("Email")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_email),callback:function ($$v) {_vm.$set(_vm.data, "delivery_email", $$v)},expression:"data.delivery_email"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{staticClass:"ml-2 ml-md-0 full-width order-form-field",attrs:{"name":"delivery-phone","rules":_vm.data.same_delivery_address ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-phone"}},[_c('span',{staticClass:"input-name"},[_vm._v("Telefon")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_phone),callback:function ($$v) {_vm.$set(_vm.data, "delivery_phone", $$v)},expression:"data.delivery_phone"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"delivery-address-1","rules":_vm.data.same_delivery_address ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-address-1"}},[_c('span',{staticClass:"input-name"},[_vm._v("Adresse")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_address_1),callback:function ($$v) {_vm.$set(_vm.data, "delivery_address_1", $$v)},expression:"data.delivery_address_1"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{staticClass:"ml-2 ml-md-0 full-width order-form-field",attrs:{"name":"delivery-address-2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-address-2"}},[_c('span',{staticClass:"input-name"},[_vm._v("Adresse fortsat")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_address_2),callback:function ($$v) {_vm.$set(_vm.data, "delivery_address_2", $$v)},expression:"data.delivery_address_2"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width order-form-field",attrs:{"name":"delivery-postcode","rules":_vm.data.same_delivery_address ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-postcode"}},[_c('span',{staticClass:"input-name"},[_vm._v("Postnr")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_postcode),callback:function ($$v) {_vm.$set(_vm.data, "delivery_postcode", $$v)},expression:"data.delivery_postcode"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('validation-provider',{staticClass:"ml-2 ml-md-0 full-width order-form-field",attrs:{"name":"delivery-city","rules":_vm.data.same_delivery_address ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-city"}},[_c('span',{staticClass:"input-name"},[_vm._v("By")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_city),callback:function ($$v) {_vm.$set(_vm.data, "delivery_city", $$v)},expression:"data.delivery_city"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width",attrs:{"name":"delivery-country","rules":_vm.data.same_delivery_address ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{class:{
                        'order-form-label': true,
                        'is-disabled': _vm.data.same_delivery_address
                      },attrs:{"for":"delivery-country"}},[_c('span',{staticClass:"input-name"},[_vm._v("Land")]),_c('c-input',{attrs:{"type":"text","disabled":_vm.data.same_delivery_address,"state":errors.length ? 'invalid' : null},model:{value:(_vm.data.delivery_country),callback:function ($$v) {_vm.$set(_vm.data, "delivery_country", $$v)},expression:"data.delivery_country"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])])],1),(_vm.submitInProgress)?[_vm._m(0)]:_vm._e()],2)])])]),_c('div',{staticClass:"wrapper-narrow"},[_c('div',{staticClass:"buttons-bar"},[_c('c-button',{staticClass:"mr-auto",attrs:{"theme":'secondary'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/order-summary')}}},[_vm._v(" Tilbage ")]),_c('c-button',{staticClass:"ml-auto",attrs:{"theme":'primary'},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_vm._v(" Bestil ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-overlay"},[_c('div',{staticClass:"loader"},[_c('span',[_vm._v("Databehandling er i gang…")])])])}]

export { render, staticRenderFns }