<template>
  <div class="c-modal-footer card-footer">
    <slot />
  </div>
</template>
<script>

export default {
  name: 'c-modal-footer'
}
</script>
