<template>
  <div class="c-modal-header card-header border-bottom">
    <slot />
    <c-button theme="close" @click.prevent="away" />
  </div>
</template>
<script>

export default {
  name: 'c-modal-header',
  methods: {
    away() {
      this.$parent.$emit('close');
    }
  }
}
</script>

