<template>
  <c-modal
    ref="cookie-banner-modal"
    :class="{ 'is-visible': isVisible, 'is-narrow is-centered': true }"
    @close="handleClose">
    <c-modal-body class="text-center text-medium">
      <p>Denne hjemmeside bruger cookies. Ved benyttelse af vores hjemmeside accepterer du brugen af cookies.</p>
      <a href="documents/privacy_policy.pdf" target="_blank" rel="nofollow noopener noreferrer">Læs mere</a>
    </c-modal-body>
    <c-modal-footer>
      <div class="flex align-items-center">
        <c-button
          theme="primary"
          class="mx-auto"
          @click.prevent="giveConsent">
          OK
        </c-button>
      </div>
    </c-modal-footer>
  </c-modal>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'cookie-banner',
  data() {
    return {
      cookiesConfig: null,
      isVisible: false,
      userConsent: false
    }
  },
  mounted() {
    this.cookiesConfig = Vue.$cookies.get('pandorakitchen-cn-accpeted');
    if (!this.cookiesConfig) {
      this.openPopup();
    } else {
      this.loadConfig();
    }
  },
  methods: {
    handleClose() {
      this.isVisible = false;
    },
    openPopup() {
      Vue.set(this, 'isVisible', true);
    },
    loadConfig () {
      if (this.cookiesConfig) {
        this.userConsent = this.cookiesConfig;
      }
    },
    giveConsent () {
      Vue.$cookies.set('pandorakitchen-cn-accpeted', true, '90d');
      this.isVisible = false;
    }
  }
}
</script>
