<template>
  <div class="c-modal-body card-body">
    <slot />
  </div>
</template>
<script>

export default {
  name: 'c-modal-body'
}
</script>

