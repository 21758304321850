import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dataFromAPI: {
      doorDivisions: [],
      doorFillings: [],
      gableFillings: [],
      gableOptions: [],
      impactFillings: [],
      inventory: [],
      inventoryCategories: [],
      wardrobePositions: []
    },
    wardrobe: {
      doorsCountOption: {},
      gable: null,
      height: null,
      inventory: [],
      isWalkInCloset: null,
      leftEndAdjustment: false,
      leftStrokeAdjustment: false,
      position: null,
      rightEndAdjustment: false,
      rightStrokeAdjustment: false,
      useImpact: false,
      doorsSettings: {}, // e.g { 0: { doorDivision: {}, doorFillings: {}, hasSoftClose: false, gable: fillingID:25 }
      width: null,
      price: {}
    },
    stepsCompletion: {
      wardrobeTypeSelection: false,
      basicInfo: false,
      numberOfDoors: false
    }
  },
  actions: {
  },
  mutations: {
    setDataDoorDivisions (state, data) {
      Vue.set(state.dataFromAPI, 'doorDivisions', data);
    },
    setDataDoorFillings (state, data) {
      Vue.set(state.dataFromAPI, 'doorFillings', data);
    },
    setDataGableFillings (state, data) {
      Vue.set(state.dataFromAPI, 'gableFillings', data);
    },
    setDataGableOptions (state, data) {
      Vue.set(state.dataFromAPI, 'gableOptions', data);
    },
    setDataImpactFillings (state, data) {
      Vue.set(state.dataFromAPI, 'impactFillings', data);
    },
    setDataInventory (state, data) {
      Vue.set(state.dataFromAPI, 'inventory', data);
    },
    setDataInventoryCategories (state, data) {
      Vue.set(state.dataFromAPI, 'inventoryCategories', data);
    },
    setDataWardrobePositions (state, data) {
      Vue.set(state.dataFromAPI, 'wardrobePositions', data);
    },
    setWardrobe (state, newWardrobestate) {
      Object.assign(state.wardrobe, newWardrobestate);
    },
    setWardrobeDoorsCountOption (state, doorsCountOption) {
      Vue.set(state.wardrobe, 'doorsCountOption', doorsCountOption);
    },
    setWardrobeDoorsSettings (state, doorsSettings) {
      Vue.set(state.wardrobe, 'doorsSettings', doorsSettings);
    },
    setWardrobeDoorsSettingsSoftClose (state, doorsSoftCloseArr) {
      for (let i = 0; i < doorsSoftCloseArr.length; i++) {
        if (!state.wardrobe.doorsSettings[i]) {
          state.wardrobe.doorsSettings[i] = {};
        }
        state.wardrobe.doorsSettings[i]['hasSoftClose'] = !!doorsSoftCloseArr[i];
      }
    },
    setWardrobeGable (state, gable) {
      Vue.set(state.wardrobe, 'gable', gable);
    },
    setWardrobeHeight (state, height) {
      Vue.set(state.wardrobe, 'height', height);
    },
    setWardrobeLeftEndAdjustment (state, leftEndAdjustment) {
      Vue.set(state.wardrobe, 'leftEndAdjustment', leftEndAdjustment);
    },
    setWardrobeLeftStrokeAdjustment (state, leftStrokeAdjustment) {
      Vue.set(state.wardrobe, 'leftStrokeAdjustment', leftStrokeAdjustment);
    },
    setWardrobePosition (state, position) {
      Vue.set(state.wardrobe, 'position', position);
    },
    setWardrobePrice (state, price) {
      Vue.set(state.wardrobe, 'price', price);
    },
    setWardrobeRightEndAdjustment (state, rightEndAdjustment) {
      Vue.set(state.wardrobe, 'rightEndAdjustment', rightEndAdjustment);
    },
    setWardrobeRightStrokeAdjustment (state, rightStrokeAdjustment) {
      Vue.set(state.wardrobe, 'rightStrokeAdjustment', rightStrokeAdjustment);
    },
    setWardrobeUseimpact (state, useImpact) {
      Vue.set(state.wardrobe, 'useImpact', useImpact);
    },
    setWardrobeWidth (state, width) {
      Vue.set(state.wardrobe, 'width', width);
    },
    setIsWalkInCloset (state, isWalkIn) {
      Vue.set(state.wardrobe, 'isWalkInCloset', isWalkIn);
      if (isWalkIn) {
        Vue.set(state.wardrobe, 'doorsCountOption', {});
        Vue.set(state.wardrobe, 'doorsSettings', {});
      }
    },
    setStepCompletion (state, step) {
      Vue.set(state.stepsCompletion, step.stepName, step.stepCompleted);
    },
    setWardrobeInventory (state, inventory) {
      Vue.set(state.wardrobe, 'inventory', inventory);
    },
    addInventoryItem (state, itemToAdd) {
      state.wardrobe.inventory.push(itemToAdd);
    },
    updateInventoryItemProp (state, { stageID, propName, newPropValue }) {
      let itemToUpdate = state.wardrobe.inventory.find(item => item.stageID === stageID);

      if (!itemToUpdate) {
        console.log('item not found ', stageID);
        return;
      }

      itemToUpdate[propName] = newPropValue;
    },
    addInventoryAttachedItem (state, { stageID, childID }) {
      let itemToUpdate = state.wardrobe.inventory.find(item => item.stageID === stageID);

      if (!itemToUpdate) {
        console.log('item not found ', stageID);
        return;
      }

      if (itemToUpdate.attachedItems.indexOf(childID) > -1) {
        return;
      }

      itemToUpdate.attachedItems.push(childID);
    },
    removeInventoryAttachedItem (state, { stageID, childID }) {
      let itemToUpdate = state.wardrobe.inventory.find(item => item.stageID === stageID);

      if (!itemToUpdate) {
        console.log('item not found ', stageID);
        return;
      }

      let childItemIndex = itemToUpdate.attachedItems.indexOf(childID);
      itemToUpdate.attachedItems.splice(childItemIndex, 1);
    },
    removeInventoryItem (state, stageID) {
      let itemToRemoveIndex = state.wardrobe.inventory.findIndex(item => item.stageID === stageID);
      if (itemToRemoveIndex > -1) {
        state.wardrobe.inventory.splice(itemToRemoveIndex, 1);

        let parent = state.wardrobe.inventory.find(item => item.attachedItems && item.attachedItems.indexOf(stageID) > -1);

        if (parent) {
          let itemIndex = parent.attachedItems.indexOf(stageID)
          parent.attachedItems.splice(itemIndex, 1);
        }
      }
    },
    resetInventory (state) {
      state.wardrobe.inventory = [];
    }
  },
  getters: {
    getDataDoorDivisions: (state) => state.dataFromAPI.doorDivisions,
    getDataDoorFillings: (state) => state.dataFromAPI.doorFillings,
    getDataGableFillings: (state) => state.dataFromAPI.gableFillings,
    getDataGableOptions: (state) => state.dataFromAPI.gableOptions,
    getDataImpactFillings: (state) => state.dataFromAPI.impactFillings,
    getDataInventory: (state) => state.dataFromAPI.inventory,
    getDataInventoryCategories: (state) => state.dataFromAPI.inventoryCategories,
    getDataWardrobePositions: (state) => state.dataFromAPI.wardrobePositions,
    getWardrobeDoorsCountOption: (state) => state.wardrobe.doorsCountOption,
    getWardrobe: (state) => state.wardrobe,
    getWardrobeDoorsSettings: (state) => state.wardrobe.doorsSettings,
    getWardrobeGable: (state) => state.wardrobe.gable,
    getWardrobeHeight: (state) => state.wardrobe.height,
    getWardrobeLeftEndAdjustment: (state) => state.wardrobe.leftEndAdjustment,
    getWardrobeLeftStrokeAdjustment: (state) => state.wardrobe.leftStrokeAdjustment,
    getWardrobePosition: (state) => state.wardrobe.position,
    getWardrobePrice: (state) => state.wardrobe.price,
    getWardrobeRightEndAdjustment: (state) => state.wardrobe.rightEndAdjustment,
    getWardrobeRightStrokeAdjustment: (state) => state.wardrobe.rightStrokeAdjustment,
    getWardrobeUseImpact: (state) => state.wardrobe.useImpact,
    getWardrobeWidth: (state) => state.wardrobe.width,
    getIsWalkInCloset: (state) => state.wardrobe.isWalkInCloset,
    getStepCompleted: (state) => state.stepsCompletion,
    getWardrobeInventory: (state) => state.wardrobe.inventory,
    getWardrobeInventoryItemsIDs: (state) => {
      if (state.wardrobe.inventory && state.wardrobe.inventory.length) {
        return state.wardrobe.inventory.map(item => {
          let outputObj = {};
          outputObj.inventory_id = item.id;
          return outputObj;
        })
      }

      return [];
    },
    getWardrobeInventoryItemsWithPositions: (state) => {
      if (state.wardrobe.inventory && state.wardrobe.inventory.length) {
        return state.wardrobe.inventory.map(item => {
          let outputObj = {};
          outputObj.inventory_id = item.id;
          outputObj.height = item.height;
          outputObj.width = item.width;
          outputObj.x_position = item.posX;
          outputObj.y_position = item.posY;
          return outputObj;
        })
      }

      return [];
    }
  }
});
