<template>
  <div class="inventory-item-thumbnail ml-1">
    <img
      :src="inventoryItem.thumb"
      :alt="inventoryItem.name" />
    <p class="">{{ inventoryItem.name }}</p>
    <p class="additional-info additional-info-price">
      <strong>Pris: {{ inventoryItem.price }}kr</strong>
    </p>
    <p class="additional-info text-tiny">
      H:{{ inventoryItem.height }} x B:{{ inventoryItem.width === 'flex' ? `${inventoryItem.min_width} - ${inventoryItem.max_width}` :  inventoryItem.width}} x D:{{ inventoryItem.depth }}mm
    </p>
  </div>
</template>
<script>

export default {
  name: 'inventory-item-thumbnail',
  props: {
    inventoryItem: {
      type: Object,
      required: true
    }
  }
}
</script>
