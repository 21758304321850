<template>
  <div class="content-wrapper">
    <div class="view-content">
      <div class="wrapper">
        <div class="main-content order-complete pb-5">
          <div class="card mx-auto">
            <h2 class="card-heading text-center">Din bestilling er nu modtaget</h2>
            <div class="card-body">
              <p class="text-center">Tak fordi du valgte at bestille hos Pandora Kitchen.</p>
              <p class="text-center">Du vil modtage en ordrebekræftelse snarest.</p>

              <div class="flex">
                <a
                  class="c-btn c-btn-small mx-auto my-2"
                  href="https://pandorakitchen.dk">
                  Gå til start
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'order-complete'
}
</script>
