<template>
  <div class="content-wrapper">
    <div class="view-content">
      <div class="wrapper">
        <div class="main-content order-summary pb-5">
          <div
            v-if="loaded && !loadError"
            class="card">
            <h2 class="card-heading">Oversigt over garderobe</h2>

            <div class="card-body">
              <div
                v-if="!wardrobePrice || !wardrobePrice.total_price"
                class="mb-3">
                <p class="alert alert-warning my-auto full-width">
                  Din Garderobe er tom, tilføj venligst inventar eller døres.
                </p>
              </div>

              <ul class="wardrobe-info-list">
                <li
                  v-if="!isWalkInCloset"
                  class="wardrobe-info-list-item">
                  <span class="mr-1">Placering:</span><span class="ml-auto">{{ wardrobeAvailablePositions.find(item => item.id === wardrobePosition).name }}</span>
                </li>
                <li class="wardrobe-info-list-item">
                  <span class="mr-1">Bredde:</span><span class="ml-auto">{{ width }} mm</span>
                </li>
                <li class="wardrobe-info-list-item">
                  <span class="mr-1">Højde:</span><span class="ml-auto">{{ height }} mm</span>
                </li>
                <li class="wardrobe-info-list-item">
                  <span class="mr-1">Gavl:</span><span class="ml-auto">{{ getGableName() }}</span>
                </li>
                <li class="wardrobe-info-list-item">
                  <span class="mr-1">Brug Anslag:</span><span class="ml-auto">{{ getHasImpact() }}</span>
                </li>
              </ul>

              <h3 class="mt-3">Skydedøre:</h3>
              <summary-table
                :is-minimal="true"
                :is-doors-summary="!isWalkInCloset"
                :products-array="doorsForSummary"
                :total-price="isWalkInCloset ? 0 : wardrobePrice.doors_price"/>

              <template v-if="hasGableImpactSection">
                <h3>{{ gableImpactSectionTitle }}:</h3>
                <summary-table
                  :is-minimal="true"
                  :products-array="gableImpactForSummary"
                  :total-price="wardrobePrice.gable_price + wardrobePrice.impact_price"/>
              </template>

              <h3>Indretning:</h3>
              <summary-table
                :products-array="inventoryForSummary"
                :total-price="wardrobePrice.inventory_price"/>

            </div>
            <div class="card-footer">
              <p class="wardrobe-total-cost my-0">Total pris: <span class="ml-2">{{ wardrobePrice.total_price }} kr</span></p>
            </div>
          </div>

          <template v-if="loadError">
            <div class="alert alert-danger my-auto full-width">
              Fejl ved indlæsning af data.
              <a
                href="javascript:window.location.reload();"
                class="alert-link">
                Opdatere siden
              </a>
              for at prøve igen.
            </div>
          </template>
          <template v-else-if="!loaded && !loadError">
            <div class="loader">
              <span>Indlæser data&hellip;</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="wrapper-narrow">
      <div class="buttons-bar">
        <c-button
          :theme="'secondary'"
          class="mr-auto"
          @click.prevent="$router.push('/inventory')">
          Tilbage
        </c-button>

        <c-button
          class="ml-auto"
          :disabled="!wardrobePrice || !wardrobePrice.total_price"
          @click.prevent="$router.push('/place-order')">
          Bestil
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonGetters from '@/utils/CommonGetters.js';
import CustomDecimals from '@/utils/CustomDecimals.js';
import SummaryTable from '@/components/layout/SummaryTable.vue';

export default {
  name: 'order-summary',
  components: {
    'summary-table': SummaryTable
  },
  computed: {
    doorsForSummary () {
      let outputArray = [];

      if (this.loaded && this.isWalkInCloset) {
        outputArray.push({
          id: 'no-doors',
          name: 'Walk-in closet',
          price: 0,
          quanity: 0
        });

        return outputArray;
      }

      if (!this.loaded || !this.doorsCountOption.number) {
        return outputArray;
      }

      for (let i = 0; i < this.doorsCountOption.number; i++) {
        let detailsArr = [];
        let currentDoorSetting = this.doorsSettings[i];
        let outputNestedArray = [];

        for (let j = 0; j < currentDoorSetting.doorDivision.divide; j++) {
          let doorPartLabel = this.getDoorPartLabel(j, currentDoorSetting.doorDivision.divide);
          if (!doorPartLabel.length) {
            doorPartLabel = 'Farve';
          } else {
            doorPartLabel += ' farve';
          }

          detailsArr.push({
            label: doorPartLabel,
            value: this.doorFillingsOptions.find(item => item.id === currentDoorSetting.doorFillings[j].fillingID).name
          });
        }

        let doorName = `Dør ${i + 1}`;
        if (currentDoorSetting.hasSoftClose) {
          doorName += ' (med softluk)'
        }
        outputNestedArray.push({
          details: `B: ${this.doorsCountOption.door_width}mm; H: ${this.height}mm`,
          detailsArray: detailsArr,
          id: 'door' + i,
          name: doorName,
          height: this.height,
          price: this.wardrobePrice.doors[i].door_price,
          quantity: 1,
          width: this.doorsCountOption.door_width,
        })

        if (currentDoorSetting.hasSoftClose) {
          outputNestedArray.push({
            details: '+Softluk',
            price: this.wardrobePrice.doors[i].soft_close_price
          })
        }

        outputNestedArray.push({
          details: 'Total Pris',
          price: this.wardrobePrice.doors[i].total_door_price
        })

        outputArray.push(outputNestedArray);
      }

      return outputArray;
    },
    gableImpactForSummary () {
      if (!this.loaded || (!this.doorsSettings.gable && !this.doorsSettings.impact)) {
        return [];
      }

      let outputArray = [];
      let detailsArr = null;
      if (this.doorsSettings.gable) {
        let gableName = this.gableOptions.find(item => item.id === this.gable).name;
        detailsArr = [{
          label: 'Farve',
          value: this.gableFillingsOptions.find(item => item.id === this.doorsSettings.gable.fillingID).name
        }];

        outputArray.push({
          name: `Gavl ${gableName}`,
          detailsArray: detailsArr,
          id: 'gable',
          price: this.wardrobePrice.gable_price,
          quanity: 1
        });
      }

      if (this.doorsSettings.impact) {
        detailsArr = [{
          label: 'Farve',
          value: this.impactFillingsOptions.find(item => item.id === this.doorsSettings.impact.fillingID).name
        }];

        outputArray.push({
          name: 'Anslag',
          detailsArray: detailsArr,
          id: 'impact',
          price: this.wardrobePrice.impact_price,
          quanity: 1
        });
      }

      return outputArray;
    },
    inventoryForSummary () {
      if (!this.inventory.length || !this.loaded) {
        return [];
      }

      let outputArray = [];

      for (let i = 0; i < this.inventory.length; i++) {
        let currentItem = this.inventory[i];
        let isGableTop = currentItem.isGableTop;
        let existingItemInArray = outputArray.find(item => item.id === currentItem.id && item.width === currentItem.width);
        let detailsAdditional = '';

        if (existingItemInArray) {
          existingItemInArray.quantity += 1;
          if (isGableTop) {
            existingItemInArray.gableTopsQuantity += 1;
            if (existingItemInArray.gableTopsQuantity > 1) {
              existingItemInArray.additionalInfo = `Inkludere ${existingItemInArray.gableTopsQuantity} top hylder`;
            } else {
              existingItemInArray.additionalInfo = `Inkludere ${existingItemInArray.gableTopsQuantity} top hylde`;
            }
          }
        } else {
          if (isGableTop) {
            detailsAdditional = 'Inkludere 1 top hylde';
          }
          outputArray.push({
            additionalInfo: detailsAdditional,
            details: `B: ${currentItem.width.toFixed(0)}mm; H: ${currentItem.height.toFixed(0)}mm; D: ${currentItem.depth.toFixed(0)}mm`,
            name: currentItem.name,
            height: currentItem.height,
            id: currentItem.id,
            price: this.wardrobePrice.inventory[currentItem.id],
            productCode: currentItem.product_code,
            quantity: 1,
            width: currentItem.width,
            gableTopsQuantity: +isGableTop
          })
        }
      }

      outputArray = outputArray.sort((CustomDecimals.sortAscBy('id')));
      return outputArray;
    }
  },
  data () {
    return {
      doorsCountOption: null,
      doorFillingsOptions: [],
      gable: null,
      gableFillingsOptions: [],
      gableImpactSectionTitle: '',
      gableOptions: [],
      hasGableImpactSection: false,
      height: null,
      impactFillingsOptions: [],
      inventory: [],
      isWalkInCloset: false,
      useImpact: false,
      doorsSettings: {},
      wardrobeAvailablePositions: [],
      wardrobePosition: null,
      wardrobePrice: {},
      width: null,
      loaded: false,
      loadError: false
    }
  },
  mounted () {
    this.gable = this.$store.getters['getWardrobeGable'];
    this.height = this.$store.getters['getWardrobeHeight'];
    this.useImpact = this.$store.getters['getWardrobeUseImpact'];
    this.wardrobePosition = this.$store.getters['getWardrobePosition'];
    this.width = this.$store.getters['getWardrobeWidth'];
    this.doorsSettings = JSON.parse(JSON.stringify(this.$store.getters['getWardrobeDoorsSettings']));
    this.doorsCountOption = this.$store.getters['getWardrobeDoorsCountOption'];

    this.gableFillingsOptions = this.$store.getters['getDataGableFillings'];
    this.doorFillingsOptions = this.$store.getters['getDataDoorFillings'];
    this.impactFillingsOptions = this.$store.getters['getDataImpactFillings'];
    this.wardrobeAvailablePositions = this.$store.getters['getDataWardrobePositions'];
    this.gableOptions = this.$store.getters['getDataGableOptions'];
    this.inventory = this.$store.getters['getWardrobeInventory'];
    this.isWalkInCloset = this.$store.getters['getIsWalkInCloset'];

    this.wardrobePrice = this.$store.getters['getWardrobePrice'];

    this.hasGableImpactSection = (this.gable || this.useImpact);

    if (this.gable) {
      this.gableImpactSectionTitle = 'Gavl';

      if (this.useImpact) {
        this.gableImpactSectionTitle += ' og anslag';
      }
    } else if (this.useImpact) {
      this.gableImpactSectionTitle = 'Anslag';
    }

    this.loaded = true;
  },
  methods: {
    getDoorPartLabel (doorPartIndex, allPartsCount) {
      return CommonGetters.getDoorPartLabel(doorPartIndex, allPartsCount);
    },
    getGableName () {
      if (!this.gable) {
        return 'ingen';
      }

      return this.gableOptions.find(item => item.id === this.gable).name;
    },
    getHasImpact () {
      if (!this.useImpact) {
        return 'nej';
      }
      return 'ja';
    },
  }
}
</script>
