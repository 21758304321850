/**
 * Core
 */
import Vue from 'vue';
import Router from 'vue-router';

/**
 * Views
 */
import Splashscreen from '@/views/SplashScreen';

// lazy-loaded parts
import StartScreen from '@/views/StartScreen';
import BasicInformation from '@/views/BasicInformation';
import NumberOfDoors from '@/views/NumberOfDoors';
import MaterialsSelection from '@/views/MaterialsSelection';
import Inventory from '@/views/Inventory';
import OrderSummary from '@/views/OrderSummary';
import PlaceOrder from '@/views/PlaceOrder';
import OrderComplete from '@/views/OrderComplete';
import OrderCancelled from '@/views/OrderCancelled';

/**
 * Subviews
 */

/**
 * Helpers
 */

// Avoid NavigationDuplicated errors
const originalPush = Router.prototype.push;

Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(error => {
    if (error && error.name !== 'NavigationDuplicated') {
      throw error;
    }
  });
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/loading',
      component: Splashscreen,
      name: 'Splashscreen'
    },
    {
      path: '/',
      redirect: '/start-screen'
    },
    {
      path: '/start-screen',
      component: StartScreen,
      name: 'Start screen'
    },
    {
      path: '/basic-information',
      component: BasicInformation,
      name: 'Basic information'
    },
    {
      path: '/number-of-doors',
      component: NumberOfDoors,
      name: 'Number of doors'
    },
    {
      path: '/materials-selection',
      component: MaterialsSelection,
      name: 'Materials selection'
    },
    {
      path: '/inventory',
      component: Inventory,
      name: 'Select inventory'
    },
    {
      path: '/order-summary',
      component: OrderSummary,
      name: 'Order summary'
    },
    {
      path: '/place-order',
      component: PlaceOrder,
      name: 'Place order'
    },
    {
      path: '/order-complete',
      component: OrderComplete,
      name: 'Order complete'
    },
    {
      path: '/order-cancelled',
      component: OrderCancelled,
      name: 'Order cancelled'
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
