<template>
  <table
    :class="{'summary-table': true, 'mb-4': true}">
    <thead v-if="!isMinimal">
      <tr>
        <th scope="col" class="text-left" style="width: calc(100% - 420px);">Navn</th>
        <th scope="col" class="text-right" style="width: 140px;">Pris</th>
        <th scope="col" class="text-right" style="width: 140px;">Antal</th>
        <th scope="col" class="text-right" style="width: 140px;">Total pris</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(product, productIndex) in productsArray">
        <template v-if="isDoorsSummary">
          <tr :key="`product-${product.type}-${productIndex}`">
            <td :colspan="2">
              <table class="summary-table-nested">
                <tbody>
                  <template v-for="(productItem, productItemIndex) in product">
                    <summary-table-row
                      :key="`product-item-${product.type}-${productItemIndex}`"
                      :is-minimal="isMinimal"
                      :product="productItem"/>
                  </template>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
        <template v-else>
          <summary-table-row
            :key="`product-${product.type}-${productIndex}`"
            :is-minimal="isMinimal"
            :product="product"/>
        </template>
      </template>
    </tbody>
    <tfoot>
      <tr>
        <td class="text-left" style="width: calc(100% - 140px);">
          <strong>Pris</strong>
        </td>
        <td class="text-right" colspan="3" style="width: 140px;">
          <strong>{{ totalPrice }} kr</strong>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import SummaryTableRow from '@/components/layout/SummaryTableRow.vue';

export default {
  name: 'summary-table',
  components: {
    'summary-table-row': SummaryTableRow
  },
  props: {
    isMinimal: {
      type: Boolean,
      default: false
    },
    isDoorsSummary: {
      type: Boolean,
      default: false
    },
    productsArray: {
      type: Array,
      required: true
    },
    totalPrice: {
      type: Number,
      default: 0
    }
  }
}
</script>
