<template>
  <section
    :class="{
      'summary': true,
      'is-horizontal': isHorizontal
    }">
    <h2 class="card-heading">Oversigt</h2>
    <div class="list-wrapper">
      <ul class="summary-list column-1">
        <li class="summary-list-item">
          <span class="mr-1">Placering:</span><span class="ml-auto">{{ wardrobePositionName }}</span>
        </li>
        <li class="summary-list-item">
          <span class="mr-1">Bredde:</span><span class="ml-auto">{{ width }} mm</span>
        </li>
        <li class="summary-list-item">
          <span class="mr-1">Højde:</span><span class="ml-auto">{{ height }} mm</span>
        </li>
        <li class="summary-list-item">
          <span class="mr-1">Gavl:</span><span class="ml-auto">{{ gableName }}</span>
        </li>
        <li class="summary-list-item">
          <span class="mr-1">Brug Anslag:</span><span class="ml-auto">{{ hasImpact }}</span>
        </li>
        <template v-if="showDoorsCount">
          <li class="summary-list-item">
            <span class="mr-1">Døre:</span><span class="ml-auto">{{ doorsForDisplay.number || 0 }}</span>
          </li>
          <li class="summary-list-item">
            <span class="mr-1">Dør bredde:</span><span class="ml-auto">{{ doorsForDisplay.width || 0 }} mm</span>
          </li>
        </template>
      </ul>
      <ul
        v-if="showDoors && doorsSettings"
        class="summary-list column-2">
        <li
          v-for="(door, doorIndex) in  doorsSettings"
          :key="doorIndex"
          class="summary-list-item door-summary">
          <template v-if="doorIndex !== 'gable' && door.doorDivision">
            <span class="full-width mb-1">Dør {{ Number(doorIndex) + 1 }}:</span>
            <div class="filling-wrapper">
              <img
                style="max-height: 90px;"
                class="mr-1 mb-1"
                :src="require('@/assets/images/svg/divide' + door.doorDivision.divide + '.svg')"
                :alt="'Dør ' + door.doorDivision.divide + ' delinger'" />
              <div
                v-for="(filling, fillingIndex) in  door.doorFillings"
                :key="fillingIndex"
                :class="{
                  'mt-auto mb-1': true,
                  'mr-1': fillingIndex < Object.keys(door.doorFillings).length - 1
                }">
                <span class="text-small">{{ getDoorPartLabel(Number(fillingIndex), Object.keys(door.doorFillings).length) }}</span>
                <div
                  class="door-filling mr-0"
                  v-bind:style="{ 'backgroundImage': getFillingStyle(filling.fillingID) }">
                </div>
              </div>
            </div>
          </template>
          <template v-if="doorIndex === 'gable'">
            <span class="full-width mb-1">Gavl farve:</span>
            <div
              class="door-filling"
              :key="doorIndex+ '-filling-' + door.fillingID + '-' + updateCounter"
              v-bind:style="{ 'backgroundImage': getFillingStyle(door.fillingID, 'gable') || '' }">
            </div>
          </template>
          <template v-if="useImpact && doorIndex === 'impact'">
            <span class="full-width mb-1">Anslag farve:</span>
            <div
              class="door-filling"
              :key="doorIndex+ '-filling-' + door.fillingID + '-' + updateCounter"
              v-bind:style="{ 'backgroundImage': getFillingStyle(door.fillingID, 'impact') || '' }">
            </div>
          </template>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import CommonGetters from '@/utils/CommonGetters.js';
import debounce from 'lodash.debounce';

export default {
  name: 'wardrobe-summary',
  props: {
    currentDoorsCountOption: {
      type: Object
    },
    currentDoorsSettings: {
      type: Object
    },
    currentDoorsSoftClosePositions: {
      type: Array
    },
    currentGable: {
      type: Number
    },
    currentHeight: {
      type: Number
    },
    currentUseImpact: {
      type: Number
    },
    currentWardrobePosition: {
      type: Number
    },
    currentWidth: {
      type: Number
    },
    isHorizontal: {
      deafult: false,
      type: Boolean
    },
    showDoors: {
      default: true,
      type: Boolean
    },
    showDoorsCount: {
      default: true,
      type: Boolean
    },
    useCurrentDoorsCountOption: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    useCurrentBasicInfo () {
      return !this.showDoors && !this.showDoorsCount;
    },
    currentIsWalkInCloset () {
      return this.$store.getters['getIsWalkInCloset'];
    },
    gableName () {
      let selectedGable = this.gable;
      if (!this.loaded || !selectedGable) {
        return 'ingen';
      }

      return this.gableOptions.find(item => item.id === selectedGable).name;
    },
    hasImpact () {
      let selectedImpact = this.useImpact;
      if (selectedImpact === undefined) {
        selectedImpact = this.useImpact;
      }

      if (selectedImpact) {
        return 'ja';
      }
      return 'nej';
    },
    wardrobePositionName () {
      let selectedPosition = this.wardrobePosition;
      if (!this.loaded || !selectedPosition) {
        return '';
      }

      return this.wardrobeAvailablePositions.find(item => item.id === selectedPosition).name;
    },
    doorsForDisplay () {
      let noValuesObj = {
        number: 0,
        width: 0
      }

      if (!this.doorsCountOption || !('number' in this.doorsCountOption) || !('door_width' in this.doorsCountOption)) {
        return noValuesObj;
      }

      return {
        number: this.doorsCountOption.number || 0,
        width: this.doorsCountOption.door_width || 0
      }
    },
    doorsSettings () {
      if (!this.loaded) {
        return {};
      }

      if (this.currentDoorsSettings) {
        return this.currentDoorsSettings;
      }

      return this.$store.getters['getWardrobeDoorsSettings'];
    },
    doorsCountOption () {
      if (!this.loaded) {
        return {};
      }

      if (this.useCurrentDoorsCountOption) {
        return this.currentDoorsCountOption;
      }

      return this.$store.getters['getWardrobeDoorsCountOption'];
    },
    gable () {
      if (!this.loaded) {
        return {};
      }

      if (this.useCurrentBasicInfo) {
        return this.currentGable;
      }

      return this.$store.getters['getWardrobeGable'];
    },
    useImpact () {
      if (!this.loaded) {
        return {};
      }

      if (this.useCurrentBasicInfo) {
        return this.currentUseImpact;
      }

      return this.$store.getters['getWardrobeUseImpact'];
    },
    height () {
      if (!this.loaded) {
        return {};
      }

      if (this.useCurrentBasicInfo) {
        return this.currentHeight;
      }

      return this.$store.getters['getWardrobeHeight'] || 0;
    },
    width () {
      if (!this.loaded) {
        return {};
      }

      if (this.useCurrentBasicInfo) {
        return this.currentWidth;
      }

      return this.$store.getters['getWardrobeWidth'] || 0;
    },
    wardrobePosition () {
      if (!this.loaded) {
        return {};
      }

      if (this.useCurrentBasicInfo) {
        return this.currentWardrobePosition;
      }

      return this.$store.getters['getWardrobePosition'];
    }
  },
  data() {
    return {
      doorFillingsOptions: [],
      gableFillingsOptions: [],
      gableOptions: [],
      impactFillingsOptions: [],
      leftEndAdjustment: false,
      leftStrokeAdjustment: false,
      loaded: false,
      rightEndAdjustment: false,
      rightStrokeAdjustment: false,
      smallestDivideNumber: 0,
      updateCounter: 0,
      wardrobeAvailablePositions: []
    };
  },
  mounted () {
    this.$bus.$on('update-wardrobe-price', this.debouncedUpdateWardrobePrice);

    this.doorFillingsOptions = this.$store.getters['getDataDoorFillings'];
    this.gableFillingsOptions = this.$store.getters['getDataGableFillings'];
    this.gableOptions = this.$store.getters['getDataGableOptions'];
    this.impactFillingsOptions = this.$store.getters['getDataImpactFillings'];

    this.leftEndAdjustment = this.$store.getters['getWardrobeLeftEndAdjustment'] || false;
    this.leftStrokeAdjustment = this.$store.getters['getWardrobeLeftStrokeAdjustment'];
    this.rightEndAdjustment = this.$store.getters['getWardrobeRightEndAdjustment'];
    this.rightStrokeAdjustment = this.$store.getters['getWardrobeRightStrokeAdjustment'];

    let doorDivisionOptions = this.$store.getters['getDataDoorDivisions'];
    this.smallestDivideNumber = doorDivisionOptions.reduce((prev, curr) => prev.divide < curr.divide ? prev : curr);

    this.wardrobeAvailablePositions = this.$store.getters['getDataWardrobePositions'];

    this.loaded = true;
  },
  methods: {
    getDoorPartLabel (doorPartIndex, allPartsCount) {
      return CommonGetters.getDoorPartLabel(doorPartIndex, allPartsCount);
    },
    getFillingStyle (selectedFillingID, sideType = '') {
      if (!selectedFillingID) {
        return '';
      }

      let selectedFilling = null;

      if (sideType === 'gable') {
        selectedFilling = this.gableFillingsOptions.find(item => item.id === selectedFillingID);
      } else if (sideType === 'impact') {
        selectedFilling = this.impactFillingsOptions.find(item => item.id === selectedFillingID);
      } else {
        selectedFilling = this.doorFillingsOptions.find(item => item.id === selectedFillingID);
      }

      if (!selectedFilling) {
        return '';
      }

      return 'url(' + selectedFilling.background + ')'
    },
    debouncedUpdateWardrobePrice: debounce(function () {
      this.updateCounter = this.updateCounter + 1;
      Vue.nextTick(() => {
        let gableFillingId = 0;
        let doorSettingsForPrice = this.doorsSettings;

        if (!this.currentIsWalkInCloset && (!this.doorsForDisplay || !this.doorsForDisplay.number)) {
          this.$store.commit('setWardrobePrice', {});
          return;
        }

        if (doorSettingsForPrice.gable && doorSettingsForPrice.gable.fillingID) {
          gableFillingId = doorSettingsForPrice.gable.fillingID;
        }
        let impactFillingId = 0;
        if (doorSettingsForPrice.impact && doorSettingsForPrice.impact.fillingID) {
          impactFillingId = doorSettingsForPrice.impact.fillingID;
        }

        let wardrobeParam = {
          height: this.height,
          gable_id: this.gable,
          gable_filling_id: this.gable ? gableFillingId : 0,
          impact: this.useImpact,
          impact_filling_id: this.useImpact ? impactFillingId : 0,
          left_end_adjustment: this.leftEndAdjustment,
          left_stroke_adjustment: this.leftStrokeAdjustment,
          right_end_adjustment: this.rightEndAdjustment,
          right_stroke_adjustment: this.rightStrokeAdjustment,
          position_id: this.wardrobePosition,
          width: this.width,
          is_walk_in_closet: this.currentIsWalkInCloset
        }

        let doorsParam = {
          number: this.doorsForDisplay.number,
          items: []
        };

        for (let i = 0; i < this.doorsForDisplay.number; i++) {
          let fillingsArr = [];
          let doorDivisionID = this.smallestDivideNumber.id;
          let hasSoftClose = !!(doorSettingsForPrice[i] && ('hasSoftClose' in doorSettingsForPrice[i]) ? doorSettingsForPrice[i].hasSoftClose : false);
          if (doorSettingsForPrice[i] && doorSettingsForPrice[i].doorDivision && doorSettingsForPrice[i].doorDivision.divide) {
            doorDivisionID = doorSettingsForPrice[i].doorDivision.id;
            for (let j = 0; j < doorSettingsForPrice[i].doorDivision.divide; j++) {
              let fillingId = 0;
              if (doorSettingsForPrice[i] && doorSettingsForPrice[i].doorFillings[j] && doorSettingsForPrice[i].doorFillings[j].fillingID) {
                fillingId = doorSettingsForPrice[i].doorFillings[j].fillingID
              }
              fillingsArr.push({
                filling_id: fillingId
              });
            }
          } else {
            fillingsArr.push({
              filling_id: 0
            });
          }

          if (this.currentDoorsSoftClosePositions) {
            hasSoftClose = !!(this.currentDoorsSoftClosePositions[i]);
          }

          doorsParam.items.push({
            division_id: doorDivisionID,
            fillings: fillingsArr,
            soft_close: hasSoftClose
          });
        }

        FormUtils.loadData(this, {
          endpoint: '/api/prices/calculate',
          method: 'post',
          noPagination: true,
          params: {
            wardrobe: wardrobeParam,
            doors: doorsParam,
            inventory: this.$store.getters['getWardrobeInventoryItemsIDs']
          },
          successAction: (response) => {
            this.$store.commit('setWardrobePrice', response);
          },
          failAction: () => {
            this.$store.commit('setWardrobePrice', {});
          }
        });
      });
    }, 250),
  },
  beforeDestroy () {
    this.$bus.$off('update-wardrobe-price', this.debouncedUpdateWardrobePrice);
  }
}
</script>
