var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:{
    'c-form-radio': true,
    'form-control': true,
    'is-valid': _vm.state === true || _vm.state === 'valid',
    'is-invalid': _vm.state === false || _vm.state === 'invalid',
    'is-checked': _vm.localChecked === _vm.value,
    'c-radio-tile': _vm.radioTile === true,
    'c-radio-with-image': _vm.withImage === true,
    'is-disabled': _vm.disabled,
    'is-big': _vm.isBig
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedLocalChecked),expression:"computedLocalChecked"}],ref:"radio",attrs:{"type":"radio","autocomplete":"off","aria-required":_vm.required ? 'true' : null,"disabled":_vm.disabled,"name":_vm.name,"required":_vm.name && _vm.required},domProps:{"value":_vm.value,"checked":_vm._q(_vm.computedLocalChecked,_vm.value)},on:{"change":[function($event){_vm.computedLocalChecked=_vm.value},_vm.handleChange]}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }