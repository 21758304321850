<template>
  <div class="splashscreen">
    <div class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'splashscreen',
  mounted () {
    console.log('splashscreen', this.$route);
    if (this.$route && this.$route.params && this.$route.params.nextRoute) {
      console.log('1');
      setTimeout(() => {
        this.$router.push(this.$route.params.nextRoute);
      }, 1000);
    } else {
      console.log('2');
      setTimeout(() => {
        this.$router.push('/start-screen');
      }, 1000);
    }
  }
}
</script>
