<template>
  <div>
    <slot />
  </div>
</template>

<script>

export default {
  name: 'main-view',
  data() {
    return {
    };
  },
};
</script>
