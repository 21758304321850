export default class CustomDecimals {
  static roundProperly (value, decimals = 2) {
    value = Number(value).toFixed(10);
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
  }
  static generateUUID () {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line
      let r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line
      return (c === 'x' ? r : (r&0x3|0x8)).toString(16);
    });

    return uuid;
  };

  static toNumberFormatted (numberString, decimals = 3) {
    let numberStringFormatted = Number(numberString).toFixed(decimals);

    if (decimals > 0 && Math.floor(numberStringFormatted) === Number(numberStringFormatted)) {
      return Number(numberStringFormatted.substr(0, numberStringFormatted.length - (decimals + 1)));
    }

    return Number(numberStringFormatted);
  };

  // eslint-disable-next-line
  static sortAscBy = (propName) => (a, b) => a[propName] == b[propName] ? 0 : a[propName] < b[propName] ? -1 : 1;
  // eslint-disable-next-line
  // static sortDescBy = (propName) => (a, b) => a[propName] == b[propName] ? 0 : a[propName] > b[propName] ? -1 : 1;
}
