<template>
  <div class="content-wrapper">
    <div class="view-content">
      <div class="wrapper">
        <div class="main-content number-of-doors pb-5">
          <validation-observer
            v-if="allDataLoaded"
            ref="nr-of-doors-form"
            tag="form"
            class="columns-wrapper">

            <div class="column column-left">
              <div class="form-group">
                <validation-provider
                  rules="required"
                  name="number-of-doors"
                  v-slot="{ errors }"
                  class="door-no-selection flex flex-column"
                  tag="div">
                  <c-form-radio
                    v-for="(count, index) in doorsCountOptions"
                    :key="index"
                    :withImage="true"
                    :value="count.number"
                    class="mr-0"
                    v-model="data.doorsCount"
                    :class="{ 'is-invalid': errors.length }">
                    <span>{{count.name}}</span>
                    <img
                      class="door-count-img"
                      :src="require('@/assets/images/door-count/' + count.number + '-doors.png')"
                      :alt="'Antal Skydedøre - ' + count.number" />
                  </c-form-radio>
                </validation-provider>
              </div>
            </div>

            <div class="column column-right">
              <div
                v-if="data.doorsCount"
                class="wardrobe-layout"
                :data-doors-count="data.doorsCount">
                <div
                  class="wardrobe-front"
                  v-bind:style="{ 'aspect-ratio': (data.width / data.height) }">
                  <div
                    v-for="(door, index) in data.doorsCount"
                    :key="index"
                    class="wardrobe-door">
                    <span class="wardrobe-dimesions wardrobe-door-width">Dør bredde: {{ currentDoorsCountOption ? currentDoorsCountOption.door_width : 0 }} mm</span>
                  </div>
                </div>

                <div
                  class="door-softclose flex"
                  :data-doors-count="data.doorsCount">
                  <div
                    v-for="(door, index) in data.doorsSoftClosePostions"
                    :key="index"
                    class="form-group">
                    <validation-provider
                      :name="'door-' + index + 1 + '-sofclose'"
                      v-slot="{ errors }">
                      <div>
                        <c-checkbox
                          v-model="data.doorsSoftClosePostions[index]"
                          @change="updatePrice">
                          Tilføj Softluk
                        </c-checkbox>
                      </div>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
          <wardrobe-summary
            v-if="allDataLoaded"
            :current-doors-count-option="currentDoorsCountOption"
            :current-doors-soft-close-positions="data.doorsSoftClosePostions"
            :show-doors="false"
            :use-current-doors-count-option="true" />
        </div>
        <template v-if="!allDataLoaded && loadError">
          <div class="alert alert-danger my-auto">
            Fejl ved indlæsning af data.
            <a
              href="javascript:window.location.reload();"
              class="alert-link">
              Opdatere siden
            </a>
            for at prøve igen.
          </div>
        </template>
        <template v-if="!allDataLoaded && !loadError">
          <div class="loader">
            <span>Indlæser data&hellip;</span>
          </div>
        </template>
      </div>
    </div>
    <div class="wrapper-narrow">
      <div class="buttons-bar">
        <c-button
          :theme="'secondary'"
          class="mr-auto"
          @click.prevent="submitForm('prev')">
          Tilbage
        </c-button>

        <c-button
          class="ml-auto"
          @click.prevent="validateForm">
          Videre
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'number-of-doors',
  computed: {
    allDataLoaded () {
      return this.loaded && this.doorsCountOptionsLoaded;
    },
    currentDoorWidth () {
      if (!this.data.doorsCount) {
        return '0';
      }

      return this.doorsCountOptions.find(item => item.number === this.data.doorsCount).door_width;
    },
    wardrobePaddingTop () {
      if (this.data.height > this.data.width) {
        // return `calc(${this.data.height} / ${this.data.width} * 100%)`;
        return `calc(${this.data.width} / ${this.data.height} * 100%)`;
      }
      // return `calc(${this.data.height} / ${this.data.width} * 100%)`;
      return `calc(${this.data.width} / ${this.data.height} * 100%)`;
    },
    currentDoorsCountOption () {
      if (!this.data.doorsCount) {
        return null;
      }
      return this.doorsCountOptions.find(item => item.number === this.data.doorsCount);
    }
  },
  watch: {
    'data.doorsCount': function (newValue) {
      if (!this.data.doorsSoftClosePostions) {
        Vue.set(this.data, 'doorsSoftClosePostions', new Array(newValue).fill(null));
      } else if (this.data.doorsSoftClosePostions.length < newValue) {
        let missingElements = new Array(newValue - this.data.doorsSoftClosePostions.length).fill(null);
        Vue.set(this.data, 'doorsSoftClosePostions', this.data.doorsSoftClosePostions.concat(missingElements));
      } else if (this.data.doorsSoftClosePostions.length > newValue) {
        this.data.doorsSoftClosePostions.splice(newValue);
      }

      this.updatePrice();
    }
  },
  data () {
    return {
      data: {
        doorsCount: null,
        doorsSoftClosePostions: [],
        gable: null,
        height: null,
        useImpact: false,
        wardrobePosition: null,
        width: null,
      },
      doorsCountOptions: [],
      doorsCountOptionsLoaded: false,
      doorDivisionOptions: [],
      gableOptions: [],
      smallestDivideNumber: null,
      wardrobeDoorsSettings: {},
      wardrobePositions: [],
      loaded: false,
      loadError: false
    }
  },
  mounted () {
    // reset price so that it would not display untill it's recalculated
    this.$store.commit('setWardrobePrice', {});

    this.data.gable = this.$store.getters['getWardrobeGable'];
    this.data.height = this.$store.getters['getWardrobeHeight'];
    this.data.useImpact = this.$store.getters['getWardrobeUseImpact'];
    this.data.wardrobePosition = this.$store.getters['getWardrobePosition'];
    this.data.width = this.$store.getters['getWardrobeWidth'];

    this.loadOptionsData();

    this.wardrobePositions = this.$store.getters['getDataWardrobePositions'];
    this.gableOptions = this.$store.getters['getDataGableOptions'];

    this.wardrobeDoorsSettings = JSON.parse(JSON.stringify(this.$store.getters['getWardrobeDoorsSettings']));
    this.doorDivisionOptions = this.$store.getters['getDataDoorDivisions'];
    this.smallestDivideNumber = this.doorDivisionOptions.reduce((prev, curr) => prev.divide < curr.divide ? prev : curr);

    this.loaded = true;
  },
  methods: {
    loadOptionsData () {
      FormUtils.loadData(this, {
        method: 'post',
        endpoint: '/api/doors/calculate/' + this.data.width,
        loadedKey: 'doorsCountOptionsLoaded',
        outputKey: 'doorsCountOptions',
        errorKey: 'loadError',
        noPagination: true,
        params: {
          has_gable: !!this.data.gable,
          has_impact: this.data.useImpact,
          wardrobe_position: this.data.wardrobePosition
        },
        successAction: (response) => {
          let doorsCountOption = this.$store.getters['getWardrobeDoorsCountOption'];
          if (doorsCountOption && doorsCountOption.number) {
            let currentOption = response.find(item => item.number === doorsCountOption.number)
            if (currentOption) {
              this.data.doorsCount = doorsCountOption.number;
              this.data.doorsSoftClosePostions = [];
              for (let i = 0; i < doorsCountOption.number; i++) {
                if (this.wardrobeDoorsSettings[i] && ('hasSoftClose' in this.wardrobeDoorsSettings[i])) {
                  this.data.doorsSoftClosePostions.push(this.wardrobeDoorsSettings[i].hasSoftClose);
                } else {
                  this.data.doorsSoftClosePostions.push(false);
                }
              }
            } else {
              this.$store.commit('setWardrobeDoorsCountOption', {});
            }
          }
          Vue.nextTick(() => {
            this.updatePrice();
          })
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['nr-of-doors-form'], this.confirmSubmitForm, this.scrollToTop);
    },
    scrollToTop () {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    },
    confirmSubmitForm () {
      if (this.$store.getters['getStepCompleted'].numberOfDoors && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.submitForm('next');
        });
      } else {
        this.submitForm('next');
      }
    },
    submitForm (param) {
      this.$store.commit('setWardrobeDoorsCountOption', this.doorsCountOptions.find(item => item.number === this.data.doorsCount));
      this.$store.commit('setWardrobeDoorsSettingsSoftClose', this.data.doorsSoftClosePostions);
      this.$store.commit('setStepCompletion', { stepName: 'numberOfDoors', stepCompleted: true });
      Vue.nextTick(() => {
        if (param === 'prev') {
          this.$router.push('/basic-information');
        } else {
          this.$router.push('/materials-selection');
        }
      });
    },
    areChangesMade () {
      let doorsCountInStore = this.$store.getters['getWardrobeDoorsCountOption'];
      if ((this.data.doorsCount && !doorsCountInStore) || (this.data.doorsCount && !doorsCountInStore.number)) {
        return false
      }
      return this.data.doorsCount !== doorsCountInStore.number;
    },
    updatePrice () {
      if (!this.doorsCountOptionsLoaded) {
        return;
      }
      this.$bus.$emit('update-wardrobe-price');
    }
  }
};
</script>
