<template>
  <c-modal
    ref="door-division-popup-modal"
    :class="{ 'is-visible': isVisible, 'is-narrow': true }"
    @close="handleClose">
    <c-modal-header>
      <h3 class="my-0">
        Vælg døre-deling til dør nr. {{ doorIndex + 1 }}
      </h3>
    </c-modal-header>

    <c-modal-body>
      <validation-observer
        ref="divisions-selection-form"
        tag="form">
        <validation-provider
          rules="required"
          name="door-divisions-options"
          class="door-divisions-options relative"
          v-slot="{ errors }">
          <c-form-radio
            v-for="(option, optionIndex) in doorDivisionOptions"
            :key="optionIndex"
            class="door-division-wrapper mr-0 mb-0"
            :withImage="true"
            :value="option.id"
            v-model="currentDoorDivisionID">
            <img
              class="door-count-img"
              :style="{'height': '100%'}"
              :src="require('@/assets/images/svg/divide' + option.divide + '.svg')"
              :alt="'Dør-deling - ' + option.divide" />
            <span class="c-form-radio-label door-divisions-name">{{ option.name }}</span>
          </c-form-radio>
          <div class="invalid-feedback absolute">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </validation-observer>
    </c-modal-body>
    <c-modal-footer>
      <div class="flex align-items-center">
        <c-button
          class="ml-0 mr-auto"
          theme="warning"
          @click.prevent="handleClose">
          Anullere
        </c-button>
        <c-button
          theme="primary"
          @click.prevent="validateForm">
          Vælg
        </c-button>
      </div>
    </c-modal-footer>
  </c-modal>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'door-division-popup',
  props: {
    doorDivisionOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      doorIndex: 0,
      currentDoorDivisionID: null,
      isVisible: false
    }
  },
  mounted() {
    this.$bus.$on('door-division-selection-popup-show', this.openPopup);
  },
  methods: {
    handleClose() {
      document.body.classList.remove('no-scroll');
      this.isVisible = false;
    },
    openPopup(doorIndex = null, currentDoorDivision = null) {
      this.doorIndex = doorIndex;
      this.currentDoorDivisionID = currentDoorDivision ? currentDoorDivision.id : null;

      setTimeout(() => {
        this.isVisible = true;
        document.body.classList.add('no-scroll');
        if (this.$refs['divisions-selection-form']) {
          this.$refs['divisions-selection-form'].reset();
        }
      }, 0);
    },
    validateForm () {
      FormUtils.validate(this.$refs['divisions-selection-form'], this.saveSelection);
      this.handleClose();
    },
    saveSelection () {
      let currentDoorDivision = null;
      if (this.currentDoorDivisionID) {
        currentDoorDivision = this.doorDivisionOptions.find(item => item.id === this.currentDoorDivisionID);
      }

      this.$bus.$emit('update-door-division', this.doorIndex, currentDoorDivision);
    }
  },
  beforeDestroy () {
    this.$bus.$off('door-division-selection-popup-show', this.openPopup);
  }
}
</script>
