<template>
  <div class="content-wrapper">
    <div class="view-content">
      <div class="wrapper">
        <div class="main-content basic-information pb-5">
          <validation-observer
            v-if="loaded"
            ref="basic-info-form"
            tag="form"
            class="columns-wrapper">

            <div class="column column-left">
              <div class="form-group">
                <validation-provider
                  :rules="`required|min_value:${minWidth}|max_value:${maxWidth}`"
                  name="wardrobe-width"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-width"
                    :class="{
                      'form-input': true,
                      'is-invalid': errors.length
                    }">
                    <span class="form-input-name">Bredde</span>
                    <c-input
                      type="number"
                      v-model="data.width"
                      :state="errors.length ? 'invalid' : null"/>
                    <span class="form-input-appendix">mm</span>
                  </label>
                  <div class="field-instructions">
                    Bredden skal være imellem {{ minWidth }}mm og {{ maxWidth }}mm.
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <validation-provider
                  :rules="`required|min_value:${minHeight}|max_value:${maxHeight}`"
                  name="wardrobe-height"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-height"
                    :class="{
                      'form-input': true,
                      'is-invalid': errors.length
                    }">
                    <span class="form-input-name">Højde</span>
                    <c-input
                      type="number"
                      v-model="data.height"
                      :state="errors.length ? 'invalid' : null"/>
                    <span class="form-input-appendix">mm</span>
                  </label>
                  <div class="field-instructions">
                    Højden skal være imellem {{ minHeight }}mm og {{ maxHeight }}mm.
                  </div>
                </validation-provider>
              </div>

              <div
                class="form-group"
                v-if="!data.isWalkInCloset">
                <validation-provider
                  name="wardrobe-use-impact"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-use-impact"
                    class="form-input">
                    <span class="form-input-name">Brug Anslag?</span>
                    <v-select
                      :clearable="false"
                      :disabled="!isImpactEnabled"
                      :options="yesNoOptions"
                      v-model="data.useImpact"
                      :reduce="(item) => item.value"
                      :searchable="false"
                      placeholder=""
                      label="label"/>
                  </label>
                </validation-provider>
              </div>

              <div
                v-if="isLeftStrokeAdjustmentEnabled"
                class="form-group">
                <validation-provider
                  rules=""
                  name="wardrobe-left-stroke-adjustment"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-left-stroke-adjustment"
                    class="form-input">
                    <span class="form-input-name">Venstre anslagtilpasning</span>
                    <v-select
                      :clearable="false"
                      :options="yesNoOptions"
                      v-model="data.leftStrokeAdjustment"
                      :reduce="(item) => item.value"
                      :searchable="false"
                      placeholder=""
                      label="label"/>
                  </label>
                </validation-provider>
              </div>

              <div
                v-if="isLeftEndAdjustmentEnabled"
                class="form-group">
                <validation-provider
                  rules=""
                  name="wardrobe-left-end-adjustment"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-left-end-adjustment"
                    class="form-input">
                    <span class="form-input-name">Venstre gavltilpasning</span>
                    <v-select
                      :clearable="false"
                      :options="yesNoOptions"
                      v-model="data.leftEndAdjustment"
                      :reduce="(item) => item.value"
                      :searchable="false"
                      placeholder=""
                      label="label"/>
                  </label>
                </validation-provider>
              </div>

              <div
                v-if="isRightStrokeAdjustmentEnabled"
                class="form-group">
                <validation-provider
                  rules=""
                  name="wardrobe-right-stroke-adjustment"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-right-stroke-adjustment"
                    class="form-input">
                    <span class="form-input-name">Højre anslagtilpasning</span>
                    <v-select
                      :clearable="false"
                      :options="yesNoOptions"
                      v-model="data.rightStrokeAdjustment"
                      :reduce="(item) => item.value"
                      :searchable="false"
                      placeholder=""
                      label="label"/>
                  </label>
                </validation-provider>
              </div>

              <div
                v-if="isRightEndAdjustmentEnabled"
                class="form-group">
                <validation-provider
                  rules=""
                  name="wardrobe-right-end-adjustment"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-right-end-adjustment"
                    class="form-input">
                    <span class="form-input-name">Højre gavltilpasning</span>
                    <v-select
                      :clearable="false"
                      :options="yesNoOptions"
                      v-model="data.rightEndAdjustment"
                      :reduce="(item) => item.value"
                      :searchable="false"
                      placeholder=""
                      label="label"/>
                  </label>
                </validation-provider>
              </div>

              <div
                v-if="!data.isWalkInCloset"
                class="form-group">
                <validation-provider
                  :rules="isGableEnabled ? 'required' : ''"
                  name="wardrobe-gable"
                  v-slot="{ errors }">
                  <label
                    for="wardrobe-gable"
                    class="form-input">
                    <span class="form-input-name">Gavl</span>
                    <v-select
                      :clearable="false"
                      :disabled="!isGableEnabled"
                      name="wardrobe-gable"
                      :options="gableOptions"
                      v-model="data.gable"
                      :reduce="(item) => item.id"
                      :searchable="false"
                      :class="{ 'is-invalid': errors.length }"
                      placeholder="Vælg galv"
                      label="name"/>
                  </label>
                </validation-provider>
              </div>
            </div>

            <div
              v-if="!data.isWalkInCloset"
              class="column column-right">
              <div class="form-group mb-0">
                <validation-provider
                  rules="required"
                  name="wardrobe-position"
                  v-slot="{ errors }"
                  tag="div"
                  class="flex flex-column">
                  <div class="wardrobe-positions flex flex-wrap">
                    <c-form-radio
                      v-for="(position, index) in wardrobePositions"
                      :key="index"
                      :withImage="true"
                      :value="position.id"
                      v-model="data.wardrobePosition"
                      :class="{ 'is-invalid': errors.length }">
                      <img
                        :src="position.image"
                        :alt="'Placering - ' + position.name"
                        class="position-image" />
                      <span class="c-form-radio-label">{{ position.name }}</span>
                    </c-form-radio>
                  </div>
                </validation-provider>
              </div>
            </div>
          </validation-observer>

          <wardrobe-summary
            v-if="loaded"
            :current-height="Number(data.height)"
            :current-gable="this.data.gable"
            :current-use-impact="+data.useImpact"
            :current-wardrobe-position="data.wardrobePosition"
            :current-width="Number(data.width)"
            :show-doors="false"
            :show-doors-count="false" />
        </div>
      </div>
    </div>
    <div class="wrapper-narrow">
      <div class="buttons-bar">
        <c-button
          :theme="'secondary'"
          class="mr-auto"
          @click.prevent="submitForm('prev')">
          Tilbage
        </c-button>
        <c-button
          theme="primary"
          class="ml-auto"
          @click.prevent="validateForm">
          Videre
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import { loadAutoSavedProject } from '@/utils/AutoSave.js';

export default {
  name: 'basic-information',
  computed: {
    isGableEnabled () {
      if (!this.data.wardrobePosition || this.data.isWalkInCloset) {
        return false;
      }

      return this.wardrobePositions.find(item => item.id === this.data.wardrobePosition).has_gable;
    },
    isImpactEnabled () {
      if (!this.data.wardrobePosition || this.data.isWalkInCloset) {
        return false;
      }

      return this.wardrobePositions.find(item => item.id === this.data.wardrobePosition).has_impact;
    },
    isLeftStrokeAdjustmentEnabled () {
      if (this.data.isWalkInCloset) {
        return false;
      }
      if (!this.data.useImpact) {
        return false;
      }

      let currentPosition = this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);

      if (currentPosition && currentPosition.impact_position && currentPosition.impact_position.indexOf('left') > -1) {
        return true;
      }

      return false;
    },
    isLeftEndAdjustmentEnabled () {
      if (this.data.isWalkInCloset) {
        return false;
      }
      let currentPosition = this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);

      if (currentPosition && currentPosition.gable_position && currentPosition.gable_position.indexOf('left') > -1) {
        return true;
      }

      return false;
    },
    isRightEndAdjustmentEnabled () {
      if (this.data.isWalkInCloset) {
        return false;
      }
      let currentPosition = this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);

      if (currentPosition && currentPosition.gable_position && currentPosition.gable_position.indexOf('right') > -1) {
        return true;
      }

      return false;
    },
    isRightStrokeAdjustmentEnabled () {
      if (this.data.isWalkInCloset) {
        return false;
      }
      if (!this.data.useImpact) {
        return false;
      }

      let currentPosition = this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);

      if (currentPosition && currentPosition.impact_position && currentPosition.impact_position.indexOf('right') > -1) {
        return true;
      }

      return false;
    }
  },
  watch: {
    'data.wardrobePosition': function (newValue) {
      if (!newValue || !this.loaded) {
        return;
      }

      let currentPosition = this.wardrobePositions.find(item => item.id === newValue);

      if (!currentPosition) {
        return;
      }

      if (!currentPosition.has_gable) {
        Vue.set(this.data, 'gable', null);
        Vue.set(this.data, 'leftEndAdjustment', false);
        Vue.set(this.data, 'rightEndAdjustment', false);
      }

      if (currentPosition.gable_position) {
        if (!currentPosition.gable_position.indexOf('right') > -1) {
          Vue.set(this.data, 'rightEndAdjustment', false);
        }

        if (!currentPosition.gable_position.indexOf('left') > -1) {
          Vue.set(this.data, 'leftEndAdjustment', false);
        }
      }

      if (!currentPosition.has_impact) {
        Vue.set(this.data, 'useImpact', false);
        Vue.set(this.data, 'leftStrokeAdjustment', false);
        Vue.set(this.data, 'rightStrokeAdjustment', false);
      }

      if (currentPosition.impact_position) {
        if (!currentPosition.impact_position.indexOf('right') > -1) {
          Vue.set(this.data, 'rightStrokeAdjustment', false);
        }

        if (!currentPosition.impact_position.indexOf('left') > -1) {
          Vue.set(this.data, 'leftStrokeAdjustment', false);
        }
      }
    },
    'data.useImpact': function (newValue) {
      if (!newValue) {
        Vue.set(this.data, 'leftStrokeAdjustment', false);
        Vue.set(this.data, 'rightStrokeAdjustment', false);
      }
    },
    'data.isWalkInCloset': function (newValue) {
      if (newValue) {
        Vue.set(this.data, 'useImpact', false);
        Vue.set(this.data, 'gable', null);
      }
    }
  },
  data () {
    return {
      data: {
        gable: null,
        height: 0,
        leftEndAdjustment: false,
        leftStrokeAdjustment: false,
        rightEndAdjustment: false,
        rightStrokeAdjustment: false,
        useImpact: false,
        wardrobePosition: null,
        width: 0,
        isWalkInCloset: false
      },
      gableOptions: [],
      wardrobePositions: [],
      yesNoOptions: [
        {
          label: 'Ja',
          value: true
        },
        {
          label: 'Nej',
          value: false
        }
      ],
      maxHeight: 2750,
      minHeight: 2048,
      maxWidth: 5000,
      minWidth: 1000,
      loaded: false
    }
  },
  mounted () {
    if (!this.$store.getters['getStepCompleted'].basicInfo) {
      let wardrobeSavedState = loadAutoSavedProject();
      if (wardrobeSavedState && wardrobeSavedState.wardrobe.isWalkInCloset === this.$store.getters['getIsWalkInCloset']) {
        this.showLoadDataPopup(wardrobeSavedState);
        return;
      }
    }
    this.loadDataFromStore();
  },
  methods: {
    loadDataFromStore () {
      Vue.set(this, 'loaded', false);
      this.data.gable = this.$store.getters['getWardrobeGable'];
      this.data.height = this.$store.getters['getWardrobeHeight'] || 0;
      this.data.leftEndAdjustment = this.$store.getters['getWardrobeLeftEndAdjustment'] || false;
      this.data.leftStrokeAdjustment = this.$store.getters['getWardrobeLeftStrokeAdjustment'];
      this.data.rightEndAdjustment = this.$store.getters['getWardrobeRightEndAdjustment'];
      this.data.rightStrokeAdjustment = this.$store.getters['getWardrobeRightStrokeAdjustment'];
      this.data.useImpact = this.$store.getters['getWardrobeUseImpact'];
      this.data.wardrobePosition = this.$store.getters['getWardrobePosition'];
      this.data.width = this.$store.getters['getWardrobeWidth'] || 0;
      this.data.isWalkInCloset = this.$store.getters['getIsWalkInCloset'];

      this.wardrobePositions = this.$store.getters['getDataWardrobePositions'];
      this.gableOptions = this.$store.getters['getDataGableOptions'];

      Vue.nextTick(() => {
        this.loaded = true;
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['basic-info-form'], this.confirmSubmitForm);
    },
    confirmSubmitForm () {
      if (this.$store.getters['getStepCompleted'].basicInfo && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.submitForm();
        });
      } else {
        this.submitForm();
      }
    },
    submitForm (param) {
      let isSettingChanged = this.areChangesMade();
      if (isSettingChanged) {
        this.$store.commit('resetInventory');
      }
      this.$store.commit('setWardrobeGable', this.data.gable);
      this.$store.commit('setWardrobeHeight', Number(this.data.height));
      this.$store.commit('setWardrobeLeftEndAdjustment', this.data.leftEndAdjustment);
      this.$store.commit('setWardrobeLeftStrokeAdjustment', this.data.leftStrokeAdjustment);
      this.$store.commit('setWardrobePosition', this.data.wardrobePosition);
      this.$store.commit('setWardrobeRightEndAdjustment', this.data.rightEndAdjustment);
      this.$store.commit('setWardrobeRightStrokeAdjustment', this.data.rightStrokeAdjustment);
      this.$store.commit('setWardrobeUseimpact', this.data.useImpact);
      this.$store.commit('setWardrobeWidth', Number(this.data.width));
      this.$store.commit('setStepCompletion', { stepName: 'basicInfo', stepCompleted: true });

      Vue.nextTick(() => {
        if (param === 'prev') {
          this.$router.push('/start-screen');
          return;
        }

        if (this.data.isWalkInCloset) {
          this.$bus.$emit('update-wardrobe-price');
          this.$router.push('/inventory');
          return;
        }
        this.$router.push('/number-of-doors');
      });
    },
    areChangesMade () {
      return this.data.wardrobePosition !== this.$store.getters['getWardrobePosition'] || this.data.width !== this.$store.getters['getWardrobeWidth'] || this.data.height !== this.$store.getters['getWardrobeHeight'] || this.data.gable !== this.$store.getters['getWardrobeGable'];
    },
    showLoadDataPopup (wardrobeSavedState) {
      Vue.swal({
        title: 'Gendan tidligere projekt.',
        text: 'Der er et tidligere projekt tilgængeligt. Vil du indlæse den?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nej',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          let creatorSteps = Object.keys(wardrobeSavedState.stepsCompletion);
          for (let i = 0; i < creatorSteps.length; i++) {
            this.$store.commit('setStepCompletion', { stepName: creatorSteps[i], stepCompleted: wardrobeSavedState.stepsCompletion[creatorSteps[i]] });
          }
          this.$store.commit('setWardrobe', wardrobeSavedState.wardrobe);
          this.$store.commit('setWardrobe', wardrobeSavedState);
        }

        Vue.nextTick(() => {
          this.loadDataFromStore();
        });
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.position-image {
  max-height: 60px;
  max-width: 100px;
}
</style>
