<template>
  <tr>
    <td
      :colspan="isMinimal ? '3' : '1'"
      class="text-left"
      style="width: calc(100% - 420px);">
      <strong class="block">{{ product.name }}</strong>
      <span
        v-if="product.productCode"
        class="my-0 text-small block">
        Produkt kode: <b>{{ product.productCode }}</b>
      </span>
      <span
        v-if="product.details"
        class="my-0 text-small block">
        {{ product.details }}
      </span>
      <span
        v-if="product.additionalInfo"
        class="my-0 text-small block">
        {{ product.additionalInfo }}
      </span>
      <template v-if="product.detailsArray">
        <span
          v-for="(item, itemIndex) in product.detailsArray"
          :key="'product-' + product.id + '-' + itemIndex"
          :class="{
            'my-0 text-small block': true,
            'mt-1': itemIndex === 0
          }">
          {{ item.label }}: <b>{{ item.value }}</b>
        </span>
      </template>
    </td>
    <td
      class="text-right"
      style="width: 140px;">
      {{ product.price }} kr
    </td>
    <template v-if="!isMinimal">
      <td
        class="text-right"
        style="width: 140px;">
        <span>{{ product.quantity }}</span>
      </td>
      <td
        class="text-right"
        style="width: 140px;">
        {{ product.price * product.quantity }} kr
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  name: 'summary-table-row',
  props: {
    isMinimal: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    }
  }
}
</script>
