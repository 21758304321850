export const autoSaveProject = (currentWardrobeState) => {
  console.log('AUTOSAVE');
  let stateToSave = JSON.stringify(currentWardrobeState);
  window.localStorage.setItem('pandora-creator-autosave', stateToSave);
};

export const loadAutoSavedProject = () => {
  let wardrobeStateToLoad = window.localStorage.getItem('pandora-creator-autosave');
  wardrobeStateToLoad = JSON.parse(wardrobeStateToLoad);
  return wardrobeStateToLoad;
};
