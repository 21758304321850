<template>
  <c-modal
    :class="{ 'is-visible': isVisible }"
    @close="handleClose">
    <c-modal-header>
      <h2 class="my-0">
        Vælg element der skal tilføjes
      </h2>
    </c-modal-header>

    <c-modal-body>
      <h3 class="mt-1">Varer tilgængelige i kategori {{ categoryName }}</h3>
      <validation-observer
        ref="inventory-selection-form"
        tag="form">
        <validation-provider
          rules="required|min_value:1"
          name="inventory-items"
          class="door-filling-options inventory-items relative"
          v-slot="{ errors }">
          <c-form-radio
            v-for="(item, index) in inventoryItems"
            :disabled="!getIsItemInsertable(item)"
            :key="index"
            class="inventory-item-wrapper mr-0"
            :withImage="true"
            :isBig="true"
            :value="item.id"
            v-model="selectedItem">
            <img
              :src="item.thumb"
              :alt="item.name" />
            <span class="c-form-radio-label">{{ item.name }}</span><br>
            <p class="additional-info additional-info-price">
              <strong>Pris: {{ item.price }}kr</strong>
            </p>
            <p class="additional-info text-tiny">
              H:{{ item.height }} x B:{{ item.width === 'flex' ? `${item.min_width} - ${item.max_width}` :  item.width}} x D:{{ item.depth }}mm
            </p>
            <p
              v-if="!getIsItemInsertable(item)"
              class="inventory-message">
              Dette element er afhængigt af et andet, der endnu ikke er tilføjet.
            </p>
          </c-form-radio>
          <div class="invalid-feedback absolute">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </validation-observer>
    </c-modal-body>
    <c-modal-footer>
      <div class="flex align-items-center">
        <c-button
          class="ml-0 mr-auto"
          theme="warning"
          @click.prevent="handleClose">
          Anullere
        </c-button>
        <c-button
          theme="primary"
          @click.prevent="validateForm">
          Tilføj
        </c-button>
      </div>
    </c-modal-footer>
  </c-modal>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'inventory-selection-popup',
  props: {
    inventoryInWardrobe: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      categoryName: '',
      selectedItem: 0,
      inventoryItems: [],
      isVisible: false
    }
  },
  mounted() {
    this.$bus.$on('inventory-selection-popup-show', this.openPopup);
  },
  methods: {
    handleClose() {
      document.body.classList.remove('no-scroll');
      this.isVisible = false;
    },
    openPopup(categoryName = '', inventoryItems = []) {
      this.categoryName = categoryName;
      this.inventoryItems = inventoryItems;
      this.selectedItem = 0;

      if (inventoryItems.length === 1) {
        this.selectedItem = inventoryItems[0].id;
      }

      setTimeout(() => {
        this.isVisible = true;
        document.body.classList.add('no-scroll');
        if (this.$refs['inventory-selection-form']) {
          this.$refs['inventory-selection-form'].reset();
        }
      }, 0);
    },
    validateForm () {
      FormUtils.validate(this.$refs['inventory-selection-form'], this.addInventory);
      this.handleClose();
    },
    addInventory () {
      this.$bus.$emit('add-item-to-wardrobe', this.selectedItem);
    },
    getIsItemInsertable (element) {
      if ((!element.fitsIn || !element.fitsIn.length) && !(element.width === 'flex')) {
        return true;
      }
      let existsItemsToFitIn = !!(element.fitsIn.length && (element.fitsIn.some(item => this.inventoryInWardrobe.indexOf(item) > -1)));
      if (element.width === 'flex' && !existsItemsToFitIn) {
        let wallsOnSecen = this.inventoryInWardrobe.filter(item => item === 2);
        return !!(wallsOnSecen && wallsOnSecen.length > 1);
      }

      return existsItemsToFitIn;
    }
  },
  beforeDestroy () {
    this.$bus.$off('inventory-selection-popup-show', this.openPopup);
  }
}
</script>
